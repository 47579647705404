import {AuthMethods} from '../auth/auth.types'
import {TaggingService} from './tagging.service'
import events from './events'
import {AdobeMemberValidationService} from './adobe/member-validation-adobe.service'
import {AdobeEventTypes, FormSubmitStatus, MemberValidationType} from './tagging.types'

export function handleAuthAnalytics(
  taggingService: TaggingService,
  adobeMemberValidationService: AdobeMemberValidationService,
  {
    method,
    isValid,
    reason = null,
    membershipNumber,
  }: { method: AuthMethods; isValid: boolean; reason?: string, membershipNumber?: string },
  params?: object
) {

  function _setAutomatedEvent(action: string, _reason: string, _params: object = null) {
    const eventParams = _reason ? { ..._params, 'Event Detail': _reason } : _params
    taggingService.setAutomatedEvent(action, events.auth.PAGE_TYPE, null, eventParams)
  }

  function _handleBypassAuthAnalytics() {
    const _action = isValid
      ? events.auth.AAA_MOBILE_AUTH_SUCCESS
      : events.auth.AAA_MOBILE_AUTH_FAILURE

    _setAutomatedEvent(_action, reason)
    adobeMemberValidationService.sendEvent(AdobeEventTypes.MEMBER_VALIDATION_RETURN, MemberValidationType.APP_SIGN_IN, {
      membership_number: membershipNumber,
      form_submit_status: isValid ? FormSubmitStatus.SUCCESS : FormSubmitStatus.FAIL,
      ...(isValid ? {} : { error_type: reason }),
    })
  }

  function _handleEncryptedAuthAnalytics() {
    const _action = isValid
      ? events.auth.AAA_NATIONAL_AUTH_SUCCESS
      : events.auth.AAA_NATIONAL_AUTH_FAILURE

    _setAutomatedEvent(_action, reason)
    adobeMemberValidationService.sendEvent(AdobeEventTypes.MEMBER_VALIDATION_RETURN, MemberValidationType.CLUB_SIGN_IN, {
      form_submit_status: isValid ? FormSubmitStatus.SUCCESS : FormSubmitStatus.FAIL,
      ...(membershipNumber ? { membership_number: membershipNumber } : {}),
      ...(isValid ? {} : { error_type: reason }),
    })
  }

  function _handleMembershipAuthAnalytics() {
    const _action = isValid
      ? events.auth.FORM_MEMBERSHIP_AUTH_SUCCESS
      : events.auth.FORM_MEMBERSHIP_AUTH_FAILURE

    _setAutomatedEvent(_action, reason, params)
    adobeMemberValidationService.sendEvent(AdobeEventTypes.MEMBER_VALIDATION_RETURN, MemberValidationType.MEMBER_NUMBER, {
      form_submit_status: isValid ? FormSubmitStatus.SUCCESS : FormSubmitStatus.FAIL,
      ...(membershipNumber ? { membership_number: membershipNumber } : {}),
      ...(isValid ? {} : { error_type: reason }),
    })
  }

  function _handleNameAuthAnalytics() {
    const _action = isValid
      ? events.auth.FORM_NAME_AUTH_SUCCESS
      : events.auth.FORM_NAME_AUTH_FAILURE

    _setAutomatedEvent(_action, reason)
    adobeMemberValidationService.sendEvent(AdobeEventTypes.MEMBER_VALIDATION_RETURN, MemberValidationType.MEMBER_NAME, {
      membership_number: membershipNumber,
      form_submit_status: isValid ? FormSubmitStatus.SUCCESS : FormSubmitStatus.FAIL,
      ...(isValid ? {} : { error_type: reason }),
    })
  }

  function _handleRapAuthAnalytics() {
    const _action = isValid
      ? events.auth.FORM_RAP_VALIDATION_SUCCESS + ' - ' + method
      : events.auth.FORM_RAP_VALIDATION_FAILURE + ' - ' + method

    _setAutomatedEvent(_action, reason)
  }

  function _handleRapTokenAuthAnalytics() {
    const _action = isValid
      ? events.auth.FORM_RAP_TOKEN_VALIDATION_SUCCESS
      : events.auth.FORM_RAP_TOKEN_VALIDATION_FAILURE

    _setAutomatedEvent(_action, reason)
  }

  switch (method) {
    case AuthMethods.AAA_TOKEN:
      _handleBypassAuthAnalytics()
      break
    case AuthMethods.AAA_NATIONAL:
      _handleEncryptedAuthAnalytics()
      break
    case AuthMethods.MEMBERSHIP_NUMBER:
      _handleMembershipAuthAnalytics()
      break
    case AuthMethods.MEMBER_NAME:
      _handleNameAuthAnalytics()
      break
    case AuthMethods.OEM:
    case AuthMethods.RENTAL:
    case AuthMethods.VAS:
      _handleRapAuthAnalytics()
      break
    case AuthMethods.RAP_TOKEN:
      _handleRapTokenAuthAnalytics()
      break
  }
}

export const HOTJAR_RECORD_EVENT_LIST = [
  events.auth.AUTH_SUCCESS_AFTER_FAILED_ATTEMPT,
  events.auth.CAPTCHA_VALIDATION_FAILURE,
  events.location.LOCATION_SERVICE_DENIED,
  events.location.LOCATION_SERVICE_ALLOWED,
  events.location.LOCATION_HOME_CLICK,
  events.location.LOCATION_SEARCH_CLICK,
  events.location.LOCATION_ADJUST_LOCATION_CLICK,
  events.location.LOCATION_5_MILES_AWAY_COORDINATES,
  events.location.LOCATION_ADDITIONAL_INFO_PROMPT,
  events.location.LOCATION_INCOMPLETE_BREAKDOWN,
  events.vehicle.VEHICLE_EDIT,
  events.vehicle.VEHICLE_YEAR_SELECT,
  events.towTo.DESTINATION_MANUAL_INPUT,
  events.towTo.DESTINATION_AAR_SELECT,
  events.towTo.DESTINATION_DISTANCE_WARNING,
  events.towTo.DESTINATION_DISTANCE_LIMIT,
  events.submit.SUBMIT_FAILURE,
  events.submit.SUBMIT_DUPLICATE_PROMPT,
  events.dashboard.CALL_CANCEL_REQUEST,
  events.dashboard.PRE_SUBMISSION_CANCEL_SURVEY,
  events.dashboard.POST_SUBMISSION_CANCEL_SURVEY
]
