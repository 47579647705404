import { Inject, Injectable } from '@angular/core'
import { select, Store } from '@ngrx/store'
import { AAAStore } from '../../store/root-reducer'
import { setEditStepLocation } from '../../modules/wizard/wizard.actions'
import { ActivatedRoute, Router } from '@angular/router'
import { selectNeedsTow } from '../../modules/issue/issue.selectors'
import { take } from 'rxjs/operators'
import { VehicleData } from '../../modules/member/member.types'
import { editVehicle } from '../../modules/vehicle/vehicle.actions'
import { clearActiveVehicle } from '../../modules/member/member.actions'
import { TaggingService } from '../../modules/tagging/tagging.service'
import { StepTypes, TowSections } from '../../modules/ui/ui.types'
import { Title } from '@angular/platform-browser'
import sharedEvents from '../../modules/tagging/events/shared'
import { AdobeEventTypes } from '../../modules/tagging/tagging.types'
import { AdobeEventService } from '../../modules/tagging/adobe/event-adobe.service'
import events from '../../modules/tagging/events'
import { RouteTypes } from '../../modules/main-router.module'
import { DRR_BASE_HREF } from '../shared.config'
import { RapService } from '../../modules/rap/rap.service'
import { selectAllowAddVehicle, selectAllowEditVehicle } from '../../modules/auth/auth.selectors'

@Injectable({
  providedIn: 'root',
})
export class EditService {

  selectAllowAddVehicle$ = this.store$.pipe(select(selectAllowAddVehicle))
  allowAddVehicle: boolean

  selectAllowEditVehicle$ = this.store$.pipe(select(selectAllowEditVehicle))
  allowEditVehicle: boolean

  constructor(
    private store$: Store<AAAStore>,
    private router: Router,
    private route: ActivatedRoute,
    private taggingService: TaggingService,
    private titleService: Title,
    private adobeEventService: AdobeEventService,
    private rapService: RapService,
    @Inject(DRR_BASE_HREF) private drrBaseHref: string
  ) {
    this.selectAllowEditVehicle$.subscribe(allowEditVehicle => this.allowEditVehicle = allowEditVehicle)
    this.selectAllowAddVehicle$.subscribe(allowAddVehicle => this.allowAddVehicle = allowAddVehicle)
  }

  // always get current active step
  get activeStep() {
    return this.route.snapshot.queryParams['step']
  }

  async editVehicleSection(active, vehicle, section) {
    this.store$.dispatch(clearActiveVehicle())
    this.store$.dispatch(
      editVehicle({
        payload: {
          vehicle: vehicle as VehicleData,
          isActive: active,
          section,
        },
      })
    )
  }

  async doEdit(step, section?, sendEvent = true, backButton = false) {
    if (sendEvent) {
      this.adobeEventService.sendEvent({
        eventName: AdobeEventTypes.CTA,
        eventValue: `${backButton ? events.submit.SUMMARY_BACK_TO_STEP : events.submit.SUMMARY_EDIT_STEP} ${step}${section ? ' ' + section : ''}`
      })
    }

    const mapStepAction = (_stepType: string, _section) => {
      switch (_stepType) {
        case StepTypes.BREAKDOWN_LOCATION:
          return sharedEvents.EDIT_BREAKDOWN_LOCATION
        case StepTypes.ISSUE:
          return sharedEvents.EDIT_ISSUE_TYPE
        case StepTypes.VEHICLE:
          return sharedEvents.EDIT_VEHICLE
        case StepTypes.TOWING:
          if (_section === TowSections.PASSENGERS) {
            return sharedEvents.EDIT_PASSENGERS
          } else {
            return sharedEvents.EDIT_TOW_DESTINATION
          }
        case StepTypes.SUBMIT:
          return sharedEvents.EDIT_SUBMIT
        default:
          break
      }
    }
    const stepType = Object.values(StepTypes).find((_stepType) => _stepType === step)
    const action = !!stepType && mapStepAction(stepType, section)
    !!action && this.taggingService.setClickEvent(action, this.titleService.getTitle())

    // Don't set a return URL if you start from the same step you are going to.
    const editionStepLocation =
      step === this.activeStep ? null : this.router.url

    // check if towing is needed always taking the last dispached value
    const needTowing = await this.store$
      .pipe(select(selectNeedsTow), take(1))
      .toPromise()

    if (!needTowing) {
      this.store$.dispatch(
        setEditStepLocation({
          payload: {
            editionStepLocation,
            step,
          },
        })
      )
    }

    const queryParams = { step }

    if (section) {
      queryParams['section'] = section
    }

    this.router.navigate([this.drrBaseHref, RouteTypes.STEPS], { queryParams })
  }

  async navigateBack() {
    const activeStep = this.activeStep;
    const skipVehicles = this.rapService.isRapUser() && !(this.allowAddVehicle && this.allowEditVehicle)
    const previousStepMap = {
      [StepTypes.TOWING]: skipVehicles ? StepTypes.ISSUE : StepTypes.VEHICLE,
      [StepTypes.VEHICLE]: StepTypes.ISSUE,
      [StepTypes.ISSUE]: StepTypes.BREAKDOWN_LOCATION,
    }

    if(activeStep === StepTypes.SUBMIT) {
      const needTowing = await this.store$
        .pipe(select(selectNeedsTow), take(1))
        .toPromise()

      if (needTowing) {
        await this.doEdit(StepTypes.TOWING, TowSections.PASSENGERS, true, true)
      } else {
        await this.doEdit(skipVehicles ? StepTypes.ISSUE : StepTypes.VEHICLE, null, true, true)
      }
    } else {
      const previousStep = previousStepMap[activeStep] || StepTypes.BREAKDOWN_LOCATION;
      await this.doEdit(previousStep, null, true, true);
    }
  }
}
