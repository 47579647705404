<ng-container *ngIf="options">
  <app-problem-checklist-v2
    *ngFor="let list of options.lists; let listIndex = index"
    [heading]="list?.heading"
    [name]="list?.name"
    [multiSelect]="list?.multiSelect"
    [selectedItems]="activeSelections[listIndex]?.members"
    [problems]="list?.values"
    [allowCollapse]="options?.lists?.length > 1"
    [open]="listIndex === 0"
    [required]="list?.mandatory"
    [tabIndexPosition]="tabIndexPosition"
    (selectionChanged)="detailSelectionChanged(groupIndex, listIndex, $event)"
  ></app-problem-checklist-v2>
</ng-container>

<ng-container *ngIf="hasAdditionalRequirements()">
  <section class="bounce-in" aria-live="assertive">
    <app-problem-checklist-v2
      [heading]="additionalRequirements?.heading"
      [name]="additionalRequirements?.name"
      [multiSelect]="additionalRequirements?.multiSelect"
      [selectedItems]="activeSelections[0]?.additional || []"
      [problems]="additionalRequirements?.values"
      [allowCollapse]="false"
      [open]="true"
      [required]="additionalRequirements?.mandatory"
      [tabIndexPosition]="tabIndexPosition"
      (selectionChanged)="additionalRequirementsChanged(groupIndex, $event)">
    </app-problem-checklist-v2>
  </section>
</ng-container>
