<div class="dashboard_container"
     id="dashboard-content"
     [ngClass]="{ 'full-height': !showMenu}">
  <app-service-tracker-header></app-service-tracker-header>
  <ng-container *ngIf="!(isRapUser$ | async)">
    <app-battery-quotes></app-battery-quotes>
  </ng-container>
  <app-tracking-info></app-tracking-info>
</div>

<!-- ARR:POC -->
<ng-container *ngIf="isAgent$ | async">
  <app-service-delivery></app-service-delivery>
</ng-container>
