import { GoogleCoordinates } from './google-geocode/types'
import { PaceSetterCode } from '../issue/issue.types'
import { BasicAddressInfo } from '../../shared/types'
import { NON_AAR_TOWING_NAMES } from './tow-location/tow-location.actions'
import { LOCATION_TYPE } from './location.actions'
import { MemberInfo } from '../member/member.types';
import { MarkerDetails } from '../ui/ui.types'

export interface GoogleLocationMarker extends GoogleCoordinates {
  isAar?: boolean
  aarId?: number
  serviceProviderCode?: string
  accuracy?: number
  address?: string
  addressLine1?: string
  addressLine2?: string
  notes?: string
  name?: string
  locationType?: string
  landmark?: string
}

export interface SimpleLocation {
  addressLine1: string
  addressLine2: string
  landmark?: string
}

export interface GoogleLocation {
  address_components?: Array<{
    long_name: string
    short_name: string
    types: string[]
  }>
  formatted_address?: string
  geometry?: {
    location: {
      lat: any
      lng: any
      accuracy?: number
    }
    location_type: string
  }
  place_id?: string
  plus_code?: Object
  types?: string[]
}

export interface GenericCoordinates {
  latitude: string | number
  longitude: string | number
  lat?: number
  lng?: number
  accuracy?: number
}

export interface MapState {
  center: GenericCoordinates
  northEastBound?: GenericCoordinates
  southWestBound?: GenericCoordinates
  zoomLevel?: number
}

export interface LocationParams {
  address: string
  location: GoogleLocation
  locationType?: LOCATION_TYPE
  coords?: any
  landmark?: string
}

export type AAALocation = {
  address?: string
  landmark?: string
  locationType?: string
  guessedLocation?: boolean
} & BasicAddressInfo &
  GenericCoordinates

export interface BreakdownLocation extends GenericCoordinates {
  postalCode: string
  state: string
  city: string
  streetName: string
  streetNumber: string
  zip?: string
  location?: string
  landmark?: string
  locationType?: string
  driverDirections?: string
}

export interface BreakdownLocationDetails {
  options: PaceSetterCode[]
  notes?: string
}

export interface TowLocation extends AAALocation {
  id?: number
  aarId?: number
  serviceProviderCode?: string
  name: NON_AAR_TOWING_NAMES | string
  facility?: string
  location?: string
  isAar?: boolean
  fullAddress?: string
  streetAddress?: string
  landmark?: string
  isCOR?: boolean
  zip?: string
  postalCode?: string
  emailAddress?: string
  country?: string
}

export interface AddressWithLandmark {
  address: string
  landmark?: string
}

export interface LocationWithMarker {
  location: TowLocation
  marker: GoogleLocationMarker
}

export enum DIRECTIONAL_NAMES {
  W = 'Westbound',
  E = 'Eastbound',
  S = 'Southbound',
  N = 'Northbound',
  WE = 'WestEastbound',
  NE = 'NorthEastBound',
  SE = 'NorthEastBound',
}

export interface HighwayExits {
  highwayLongName: string
  highwayShortName: string
  exitBehind?: HighwayExitInfo
  exitAhead?: HighwayExitInfo
}

export interface HighwayExitInfo {
  latitude: string | number
  longitude: string | number
  exitNumber?: string | number
  exitName?: string
  direction?: string
}

export interface LocationClubResponse {
  servicingClub: string
  country: string
}

export enum TOW_DISTANCE {
  LIMIT = 100,
  WARNING = 50,
}

export enum TOWING_ALERT_TYPES {
  DEFAULT = 'default',
  CUSTOM = 'custom',
  RAP = 'rap',
}

export interface TowingDistanceCoords {
  breakdownCoordinates: GenericCoordinates
  towLocation: GoogleCoordinates
  destinationId?: number
}

export enum LOCATION_STEPS {
  MAP = 'MAP',
  MAP_OPTIONS = 'MAP_OPTIONS',
}

export const USE_CURRENT_LOCATION = 'USE_CURRENT_LOCATION'

export interface LastSearchLocation {
  description?: string
  landmark?: string
  main_text?: string
  secondary_text?: string
}

export enum AAR_FILTER_TYPES {
  ALL = 'ALL',
  OPEN = 'OPEN',
  TOW_DISTANCE_LIMIT = 'TOW_DISTANCE_LIMIT',
}

export enum AAR_SORTING_TYPES {
  DEFAULT = 'DEFAULT',
  DISTANCE = 'DISTANCE',
  RATING = 'RATING',
  NO_RATING = 'NO_RATING'
}

export enum AAR_SORTING_ORDER {
  DEFAULT = 'DEFAULT',
  ASCENDING = 'asc',
  DESCENDING = 'desc'
}

export interface AARSorting {
  type: AAR_SORTING_TYPES
  order: AAR_SORTING_ORDER
}

export interface MapBoundsPadding {
  top: number
  left: number
  right: number
  bottom: number
}

export enum SearchViews {
  BREAKDOWN_LOCATION = 'BREAKDOWN_LOCATION',
  TOWING_DESTINATION = 'TOWING_DESTINATION',
}

export interface LocationCard {
  display: boolean
  loading: boolean
  displayHomeItem: boolean
  member: MemberInfo
}

export interface LocationMapTemplate {
  static: boolean
  centerMarker?: MarkerDetails
}
