<div *ngIf="options" class="situation-details">
  <div
    *ngIf="options.heading"
    class="situation-details_heading"
    role="heading"
    aria-level="1"
    [ngClass]="[options?.headingClass || '']"
    [innerHTML]="options.heading || ''"
  ></div>

  <div class="situation-details_subheading"  [ngClass]="[options?.subHeadingClass || '']">
    <ng-container *ngIf="!(isRapUser$ | async) || options.subHeading !== 'Additional fees may apply depending on membership level.'">
      <span *ngIf="options.subHeading" [innerHTML]="options.subHeading || ''"></span>
    </ng-container>
    <span *ngIf="options.isOptional" class="situation-details_optional">&nbsp;(Optional)</span>
  </div>
  <app-problem-checklist
    *ngFor="let list of options.lists; let listIndex = index"
    [heading]="list?.heading"
    [name]="list?.name"
    [multiSelect]="list?.multiSelect"
    [selectedItems]="activeSelections[listIndex]?.members"
    [problems]="list?.values"
    [allowCollapse]="options?.lists?.length > 1"
    [open]="listIndex === 0"
    [required]="list?.mandatory"
    [tabIndexPosition]="tabIndexPosition"
    (selectionChanged)="detailSelectionChanged(groupIndex, listIndex, $event)"
  ></app-problem-checklist>
</div>

<ng-container *ngIf="(additionalRequirement$ | async)">
  <section class="bounce-in" aria-live="assertive">
    <app-problem-checklist
      [heading]="additionalRequirements?.heading"
      [name]="additionalRequirements?.name"
      [multiSelect]="additionalRequirements?.multiSelect"
      [selectedItems]="activeSelections[0]?.additional || []"
      [problems]="additionalRequirements?.values"
      [allowCollapse]="false"
      [open]="true"
      [required]="additionalRequirements?.mandatory"
      [tabIndexPosition]="tabIndexPosition"
      (selectionChanged)="additionalRequirementsChanged(groupIndex, $event)">
    </app-problem-checklist>
  </section>
</ng-container>
