<h4 aria-level="4" class="summary-title" i18n>Your Request Summary</h4>

<div class="line" id="summary-issue">
  <div class="line-title">
    <app-img
      [src]="paceSetterCode | issue:'icon'"
      classNames="icon"
      [alt]="(paceSetterCode | issue:'title') || 'Issue'"
      height="32px"
      width="32px"
    ></app-img>
    <div class="label small-text" i18n>Issue:</div>
  </div>

  <div class="tiny-text description">{{paceSetterCode | issue:'title' }}</div>
</div>

<ng-container *ngIf="vehicle">
  <div class="line" id="summary-vehicle">
    <div class="line-title">
      <app-img
        src="assets/drrweb-lib/images/icons/car-small.svg"
        classNames="icon"
        alt="Vehicle info"
        i18n-alt
      >
      </app-img>
      <div class="label small-text" i18n>Your Vehicle:</div>
    </div>
    <div class="tiny-text description">{{ vehicle | vehicle:'color' }}</div>
  </div>
</ng-container>

<ng-container *ngIf="towDestination$ | async as destination">
  <div class="line destination-container" id="summary-tow-location">
    <div class="line-title">
      <app-img
        src="assets/drrweb-lib/images/icons/tow-small.svg"
        classNames="icon"
        alt="Towing location"
        i18n-alt
      ></app-img>
      <div class="label small-text" i18n>Tow To:</div>
    </div>
    <div class="tiny-text">
      <div>{{ destination?.address }}</div>
    </div>
    <ng-container *ngIf="destination?.isAar">
      <app-img
        classNames="approved-logo"
        src="assets/drrweb-lib/images/AAA Approved.svg"
        alt="AAA Approved"
        i18n-alt
      ></app-img>
    </ng-container>
  </div>
</ng-container>

<ng-container *ngIf="(canCancel$ | async)">
  <div class="cancel-request" id="cancel-request">
    <app-button-cancel-request></app-button-cancel-request>
  </div>
</ng-container>
