import { Component, ViewEncapsulation } from '@angular/core'
import { select, Store } from '@ngrx/store'
import { AAAStore } from '../../../store/root-reducer'
import { PaceSetterOptions, PaceSetterSituation } from './../issue.types'
import {
  selectActivePaceSetterSituation,
  selectActivePaceSetterStep,
  selectActiveSituationTemplate,
} from './../issue.selectors'
import { Observable } from 'rxjs'
import { setPaceSetterSituation, setPaceSetterStep } from './../issue.actions'
import { TaggingService } from '../../tagging/tagging.service'
import { addPartialCallRequest } from '../../dashboard/calls.actions'
import events from '../../tagging/events'
import { selectIsRapUser, selectModeConfiguration } from '../../auth/auth.selectors'
import { VendorConfigurationMode } from '../../auth/mode-configuration.types'
import { Title } from '@angular/platform-browser';
import { RapService } from '../../rap/rap.service';
import { buildTitle } from '../../../shared/utils/title';
import { EditService } from '../../../shared/services/edit.service'

const TITLE_PAGE = () => $localize`What Happened?`

@Component({
  styleUrls: ['./issue-step.component.scss'],
  templateUrl: './issue-step.component.html',
  selector: 'app-issue-step',
  encapsulation: ViewEncapsulation.None,
})
export class IssueStepComponent {
  isDesktop: boolean

  activeStep$: Observable<number> = this.store$.pipe(
    select(selectActivePaceSetterStep)
  )

  activeSituation$: Observable<PaceSetterSituation> = this.store$.pipe(
    select(selectActivePaceSetterSituation)
  )

  currentTemplate$: Observable<PaceSetterOptions> = this.store$.pipe(
    select(selectActiveSituationTemplate)
  )

  modeConfiguration$: Observable<VendorConfigurationMode> = this.store$.pipe(
    select(selectModeConfiguration)
  )
  rapConfig: VendorConfigurationMode

  isRapUser$ = this.store$.pipe(select(selectIsRapUser))

  isIssueSelected = false

  constructor(
    private store$: Store<AAAStore>,
    private taggingService: TaggingService,
    private titleService: Title,
    private rapService: RapService,
    private editService: EditService,
  ) {
    this.titleService.setTitle(buildTitle(TITLE_PAGE(), this.rapService.isRapUser()))
    this.taggingService.setPageEvent(events.issue.ISSUE_PAGE_PROMPT, events.issue.ISSUE_PAGE_TYPE)

    this.modeConfiguration$.subscribe((configData: VendorConfigurationMode) => this.rapConfig = configData)
  }

  problemSelected(problem: PaceSetterSituation) {
    this.store$.dispatch(setPaceSetterSituation({ payload: problem }))
    this.store$.dispatch(addPartialCallRequest())

    this.taggingService.setClickEvent(events.issue.ISSUE_PAGE_NEXT_CLICK, events.issue.ISSUE_PAGE_TYPE)

    this.store$.dispatch(setPaceSetterStep({ index: 1 }))
    this.isIssueSelected = true
  }

  handleCloseSituation() {
    this.isIssueSelected = false
  }

  navigateBack() {
    this.editService.navigateBack();
  }

}
