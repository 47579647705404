<app-header-redesign>
  <app-back-button slot="start" (aaaClick)="navigateBack()" />
  <ion-title>
    <app-logo />
  </ion-title>
  <app-closable-header slot="end" />

  <app-stepper-redesign />
</app-header-redesign>

<ion-content
  scrollEvents="true"
  (ionScroll)="handleScroll($event)"
>
  <form class="contact-form"
        novalidate
        [ngrxFormState]="contactForm"
        (ngSubmit)="handleCallSubmit()">
    <ion-grid class="ion-no-padding" [ngClass]="{ 'extra-margin-bottom': isMobile }">
      <ion-row class="ion-padding position-fixed scroll-submit bg-contrast" *ngIf="isMobile" [class.visible]="showAnchorSubmit">
        <ion-col>
          <ng-template [ngTemplateOutlet]="submitButton"></ng-template>
        </ion-col>
      </ion-row>
      <ion-row class="ion-margin">
        <ion-col>
          <aaa-text i18n color="text" size="title3" weight="bold">Review and Submit</aaa-text>
          <aaa-text i18n color="text" size="subheadline">You're almost there.</aaa-text>
        </ion-col>
      </ion-row>
      <ion-row class="ion-margin">
        <ion-col>
          <ion-textarea
            i18n-label
            id="additionalDetails"
            name="additionalDetails"
            label="Additional Details"
            labelPlacement="floating"
            mode="md"
            fill="outline"
            [ngrxFormControlState]="controls?.additionalDetails"
            [maxlength]="DEFAULT_TEXT_LENGTH"
            [tabIndex]="910"
            [counter]="true"
          ></ion-textarea>
        </ion-col>
      </ion-row>
      @if(isRapUser) {
        <ion-row class="ion-margin-horizontal ion-margin-top">
          <ion-col class="ion-margin-top">
            <aaa-text i18n class="margin-bottom-8" size="headline" color="text" id="nameLabelText" weight="medium">* Who will be waiting with the vehicle?</aaa-text>
          </ion-col>
        </ion-row>
        <ion-row class="ion-margin-horizontal ion-padding-bottom">
          <ion-col>
            <aaa-input
              #firstNameInput
              autocomplete="given-name"
              aria-required="true"
              label="First Name"
              i18n-label
              id="firstName"
              name="firstName"
              required
              type="text"
              variant="secondary"
              [maxLength]="maxLengthName"
              [ngrxFormControlState]="controls?.firstName"
              (paste)="$event.preventDefault()"
              [tabIndex]="913"
              >
                <app-form-helper i18n helper [control]="controls?.firstName" name='firstName' type='required'>First Name is required.</app-form-helper>
            </aaa-input>
          </ion-col>
        </ion-row>
        <ion-row class="ion-margin">
          <ion-col class="ion-padding-bottom">
            <aaa-input
              #lastNameInput
              autocomplete="family-name"
              aria-required="true"
              label="Last Name"
              i18n-label
              id="lastName"
              name="lastName"
              required
              type="text"
              variant="secondary"
              [maxLength]="maxLengthName"
              [ngrxFormControlState]="controls?.lastName"
              (paste)="$event.preventDefault()"
              [tabIndex]="914"
            >
              <app-form-helper i18n helper [control]="controls?.lastName" name='lastName' type='required'>Last Name is required.</app-form-helper>
            </aaa-input>
          </ion-col>
        </ion-row>
      }

      <ion-row class="ion-margin">
        <ion-col class="ion-margin-bottom">
          <aaa-text i18n class="margin-bottom-8" size="headline" color="text" weight="medium">* How can the driver reach you?</aaa-text>
          <aaa-input
            #phoneInput
            i18n-label
            label="Phone Number"
            type="tel"
            autocomplete="tel-national"
            variant="secondary"
            id="contactNumber"
            name="contactNumber"
            aria-required="true"
            required
            mask="us_phone_number"
            [ngrxFormControlState]="controls?.contactNumber"
            [ngrxValueConverter]="phoneValueConverter"
            [tabIndex]="910"
            (paste)="$event.preventDefault()"
          >
            <app-form-helper i18n
                             helper
                             [control]="controls?.contactNumber"
                             name='contactNumber'
                             type='required'
            >
              Phone Number is required.
            </app-form-helper>
            <app-form-helper i18n
                             helper
                             [control]="controls?.contactNumber"
                             name='contactNumber'
                             type='pattern'
            >
              10-digit Phone Number is required.
            </app-form-helper>
          </aaa-input>
        </ion-col>
      </ion-row>
      <ion-row class="ion-margin">
        <ion-col>
          <aaa-text i18n *ngIf="!isRapUser" class="disclaimer" size="footnote" color="text" >By submitting my request, I agree to present a valid Photo ID to my service provider and that my vehicle’s tags and registration are current.</aaa-text>
        </ion-col>
      </ion-row>
      <ion-row class="ion-margin">
        <ion-col>
          <ion-grid class="ion-no-padding ion-margin-top">
            <ion-row class="ion-align-items-center">
              <ion-col>
                <aaa-text i18n class="margin-bottom-8" size="headline" color="text" weight="medium">Text me updates</aaa-text>
                <aaa-text i18n size="footnote" color="text">Messages and data rates apply. You may also receive follow-up surveys via text.</aaa-text>
              </ion-col>
              <ion-col size="auto" class="ion-padding-start">
                <ion-toggle
                  name="updates"
                  [ngrxFormControlState]="controls?.shouldTextUpdates"
                  [tabIndex]="911"
                  (ionChange)="handleTextUpdate()"></ion-toggle>
              </ion-col>
            </ion-row>
          </ion-grid>
        </ion-col>
      </ion-row>

      <ion-row class="ion-margin">
        <ion-col class="ion-margin-top">
          <aaa-text class="margin-bottom-8" size="headline" color="text" weight="medium" i18n>Request Details</aaa-text>
          <app-request-details></app-request-details>
        </ion-col>
      </ion-row>
      <ion-row class="ion-margin" *ngIf="!isMobile">
        <ion-col>
          <ng-template [ngTemplateOutlet]="submitButton"></ng-template>
        </ion-col>
      </ion-row>
    </ion-grid>
  </form>
</ion-content>

<ng-template #submitButton>
  <aaa-button
    type="submit"
    id="submit-my-request-btn"
    variant="accent"
    expand="block"
    [loading]="isCallRequesting$ | async"
    [disabled]="isCallRequesting$ | async"
    [tabIndex]="915">
    <aaa-icon *ngIf="!(isCallRequesting$ | async)" name="send" size="medium"></aaa-icon>
    <ng-container i18n>Submit Request</ng-container>
  </aaa-button>
  <aaa-text i18n *ngIf="displayCustomWarning" size="footnote" class="ion-padding" color="danger">
    Your request is taking a little longer than expected! Thank you for your patience.
  </aaa-text>
</ng-template>
