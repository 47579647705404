<div class="wrapper" #mapWrapper aria-hidden="true" role="none" tabindex="-1">
  <map-touch-layer
    (stopped)="handleStop($event)"
    [blockOverlay]="blockOverlay"
    [preventTouch]="preventTouch">
    <google-map
      *ngIf="apiLoaded"
      id="map"
      width="100%"
      height="100%"
      [center]="center"
      tabindex="-1"
      [zoom]="zoomLevel"
      [options]="{
        scrollwheel: false,
        keyboardShortcuts: false,
        maxZoom: MAX_MAP_ZOOM,
        gestureHandling: this.static || this.blockOverlay ? 'none' : 'greedy',
        disableDefaultUI: true,
        styles: [
          {
            featureType: 'poi',
            elementType: 'labels',
            stylers: [{ visibility: 'on' }]
          },
          {
            featureType: 'poi',
            elementType: 'geometry',
            stylers: [{ visibility: 'on' }]
          },
        ],
      }"
      (mapInitialized)="bootstrapMap($event)">
      <app-custom-marker
        *ngIf="showGpsLocation && userLocation?.lat && userLocation.lng && nativeMap"
        [location]="userLocation"
        [map]="nativeMap"
        [data]="defaultUserLocationData"
        [tabIndex]="tabIndexPosition + 2"
      >
        <app-img src="assets/drrweb-lib/images/gps-dot.png"></app-img>
      </app-custom-marker>

      <ng-container [ngSwitch]="view">
        <ng-container *ngSwitchCase="'BREAKDOWN_LOCATION'">
          <ng-container *ngIf="userLocation?.lat && userLocation.lng && nativeMap">
            <app-custom-marker
              [location]="userLocation"
              [map]="nativeMap"
              [data]="defaultUserLocationData"
              (markerClicked)="markerClick()"
              [tabIndex]="tabIndexPosition + 2"
            >
              <app-img src="assets/drrweb-lib/images/blue-dot.jpg"></app-img>
            </app-custom-marker>
          </ng-container>
        </ng-container>

        <ng-container *ngSwitchCase="'TOWING_DESTINATION'">
          <ng-container *ngIf="breakdownMarker?.lat && breakdownMarker.lng" class="breakdown-pin">
            <app-custom-marker
              id="breakdown-pin"
              [map]="nativeMap"
              [location]="breakdownMarker"
              [data]="breakdownMarkerRender"
              [tabIndex]="(tabIndexPosition - 200)">
              <icon-breakdown-location-pin [small]="true"></icon-breakdown-location-pin>
            </app-custom-marker>
          </ng-container>

          <ng-container *ngFor="let shop of aarShopsLocation; let shopIndex = index;">
            <app-custom-marker id="map-pin-aar-{{ aarShopsLocation.length - shopIndex - 1 }}"
              [location]="shop.marker"
              [map]="nativeMap"
              [data]="getAarMarkerRender(shop.marker)"
              (markerTouchStart)="handleAarClick(shop)"
              [tabIndex]="(tabIndexPosition - DEFAULT_INDEX_THRESHOLD) + (selectedLocation === shopIndex ? aarShopsLocation.length + shopIndex : 0)">
              <icon-ev-station-pin
                *ngIf="shop.location.id !== towDestinationMarker?.aarId && selectIsEVstation$ | async"
                id="icon-aar-pin-{{aarShopsLocation.length - shopIndex}}"
                [selected]="selectedLocation === shopIndex">
              </icon-ev-station-pin>
              <icon-aar-destination-pin id="icon-aar-pin-{{aarShopsLocation.length - shopIndex}}"
              *ngIf="shop.location.id !== towDestinationMarker?.aarId && !(selectIsEVstation$ | async)"
                [isSelected]="selectedLocation === shopIndex"
                [isCOR]="shop.location.isCOR"
                [rapAppId]="rapAppId">
              </icon-aar-destination-pin>
            </app-custom-marker>
          </ng-container>

          <ng-container *ngIf="towDestinationMarker?.lat && towDestinationMarker.lng">
            <app-custom-marker
              [location]="towDestinationMarker"
              [map]="nativeMap"
              [data]="getTowMarkerRender()"
              (markerTouchStart)="handleAarClick(towDestinationMarker)"
              [tabIndex]="(tabIndexPosition + 3) + (aarShopsLocation.length || 0)">

              <icon-ev-station-pin
                *ngIf="towDestinationMarker.aarId && (selectIsEVstation$ | async)"
                id="icon-aar-pin-ev-selected"
                selected="true">
              </icon-ev-station-pin>
              <icon-aar-destination-pin
                id="icon-aar-pin-aar-selected"
                isSelected="true"
                *ngIf="towDestinationMarker.aarId && !(selectIsEVstation$ | async)"
                [rapAppId]="rapAppId">
              </icon-aar-destination-pin>
              <icon-tow-location-pin
                class="tow-location-destination-marker"
                [small]="true"
                [isSelected]="selectedLocation === null"
                *ngIf="!towDestinationMarker.aarId">
              </icon-tow-location-pin>
            </app-custom-marker>
          </ng-container>
        </ng-container>

        <ng-container *ngFor="let customMarker of customMarkers">
          <app-custom-marker
            #customMarkerComponents
            id="{{customMarker.id}}"
            [location]="customMarker.location"
            [map]="nativeMap"
            (markerTouchStart)="handleCustomMarkerClick(customMarker)"
            [data]="customMarker.markerDetails"
            [tabIndex]="customMarker.tabIndex">
            <app-map-icon [markerDetails]="customMarker.markerDetails"></app-map-icon>
          </app-custom-marker>
        </ng-container>
      </ng-container>
    </google-map>
  </map-touch-layer>

  <ng-container *ngIf="centerMarker">
    <app-map-icon class="center-marker" [markerDetails]="centerMarker"></app-map-icon>
  </ng-container>

  <ng-container *ngIf="view === 'BREAKDOWN_LOCATION'">
    <icon-breakdown-location-pin
      id="breakdown-pin"
      class="custom-marker"
      [ngClass]="{'blocked': blockOverlay}"
      role="figure"
      aria-labelledby="breakdown-pin"
    >
    </icon-breakdown-location-pin>

    <ng-container *ngIf="userLocation?.lat && userLocation.lng && nativeMap && !preventTouch && !blockOverlay" >
      <div class="bottom-actions-controls">
         <button
           aria-label="locate-user"
           (click)="locateUser()"
           [tabIndex]="tabIndexPosition + 5">
           <icon-locate color="white"></icon-locate>
         </button>
       </div>
     </ng-container>
  </ng-container>

  <ng-container *ngIf="!isMobile && !isRedesign">
    <div class="buttons-holder" [ngClass]="{ 'custom-bottom': view === 'TOWING_DESTINATION'}">
      <button id="zoom-in-btn"
        aria-label="zoom in"
        (click)="customZoomfn('in',  nativeMap.getZoom())"
        [tabIndex]="tabIndexPosition + 5">
        +
      </button>
      <button id="zoom-out-btn"
        aria-label="zoom out"
        (click)="customZoomfn('out',  nativeMap.getZoom())"
        [tabIndex]="tabIndexPosition + 5"
        >
        -
      </button>
    </div>
  </ng-container>

  <ng-container *ngIf="isRedesign">
    <div class="right-side-slot">
      <div class="zoom-action" *ngIf="displayLocateMeAction">
        <aaa-button
          variant="primary"
          size="small"
          i18n-aria-label
          aria-label="locate user"
          (click)="locateUser()"
          [tabIndex]="tabIndexPosition + 5">
          <aaa-icon name="navigate-circle-outline"></aaa-icon>
        </aaa-button>
      </div>
      <div class="zoom-action" *ngIf="displayZoomAction">
        <aaa-button
          size="small"
          variant="on-map"
          i18n-aria-label
          aria-label="zoom in"
          (click)="zoomIn()"
          [tabIndex]="tabIndexPosition + 5">
          <aaa-icon name="add-outline"></aaa-icon>
        </aaa-button>
        <aaa-button
          size="small"
          variant="on-map"
          i18n-aria-label
          aria-label="zoom out"
          (click)="zoomOut()"
          [tabIndex]="tabIndexPosition + 5"
        >
          <aaa-icon name="remove-outline"></aaa-icon>
        </aaa-button>
      </div>
    </div>
  </ng-container>

  <div class="top-slot">
    <ng-content select="[topSlot]"></ng-content>
  </div>

  <div class="bottom-slot">
    <ng-content select="[bottomSlot]"></ng-content>
  </div>

  <ng-container *ngIf="view === 'BREAKDOWN_LOCATION' && showActionButtons">
    <div [ngClass]="{'bottom-actions-vertical': blockOverlay, 'bottom-actions-horizontal': !blockOverlay}" class="bottom-actions">
      <div id="find-my-location_container">
        <app-button i18n
          id="find-my-location"
          class="find-my-location"
          *ngIf="!isValidBreakdownLocation"
          [disabled]="hasDeniedGpsAccess || isLoading"
          [loading]="isLoading"
          (buttonClick)="handleFindMyLocation()"
          [color]="'primary-yellow'"
          [tabIndex]="tabIndexPosition + 7"
          [hasShadow]="true"
          type="submit">
          Find My Location
        </app-button>
        <span class="tooltiptext" *ngIf="hasDeniedGpsAccess && !isValidBreakdownLocation">
          <p i18n>Cannot access your location.</p>
          <a i18n (click)="help()">Learn more</a>
        </span>
      </div>
      <app-button i18n
        id="adjust-pin"
        class="adjust-pin"
        *ngIf="blockOverlay && hasDeniedGpsAccess"
        (buttonClick)="handleAdjustPin()"
        [color]="'primary-yellow'"
        [tabIndex]="tabIndexPosition + 7"
        [hasShadow]="true"
        type="submit">
        Adjust Pin
      </app-button>
      <app-button i18n
        class="adjust-location"
        *ngIf="isValidBreakdownLocation"
        (buttonClick)="handleAdjustLocation()"
        appCtaClickEvent
        [color]="'primary-no-uppercase'"
        [tabIndex]="tabIndexPosition + 7"
        [hasShadow]="true"
        type="submit">
        Adjust Location
      </app-button>
    </div>
  </ng-container>

  <ng-container *ngIf="view === 'TOWING_DESTINATION' && (showActionButtons || isLoading)">
    <div class="top-actions">
      <app-button class="search-area" (buttonClick)="handleSearchArea()" [disabled]="isLoading"
        [color]="'primary-no-uppercase'" [tabIndex]="tabIndexPosition + 7" [hasShadow]="true" type="submit">
        <ng-container *ngIf="!isLoading; else loading">
          <i i18n-alt class="fa-solid fa-magnifying-glass left-icon-button" alt="Search this area"></i>
          <ng-container i18n>Search this area</ng-container>
        </ng-container>
        <ng-template #loading>
          <i class="fa-solid fa-spinner fa-spin"></i> <ng-container i18n>Loading facilities</ng-container>
        </ng-template>
      </app-button>
    </div>
  </ng-container>

  <ng-container *ngIf="showSelectedTowDestination && view === 'TOWING_DESTINATION'">
    <div class="bottom-selected-shop">
      <app-tow-location-selected [tabIndex]="tabIndexPosition + 8" (onShopDetailsClose)="closeShopDetails()">
      </app-tow-location-selected>
    </div>
  </ng-container>
</div>
