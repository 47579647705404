import { Component, Inject, OnDestroy, OnInit } from '@angular/core'
import { Observable } from 'rxjs'
import { AAAStore } from '../../store/root-reducer'
import { select, Store } from '@ngrx/store'
import { CallStatusService } from './calls-statuses/call-status.service'
import {
  selectCallStatusMessage,
  selectHasAvailableCall,
  selectIsTrackingInfoAvailable
} from './calls-statuses/call-status.selectors'
import { Router } from '@angular/router'
import { Title } from '@angular/platform-browser'
import { buildTitle } from '../../shared/utils/title'
import { AbstractComponent } from '../../shared/abstract.component'
import { selectShowMenu } from '../ui/ui.selectors'
import { StepTypes } from '../ui/ui.types'
import { selectIsAgent, selectIsRapUser } from '../auth/auth.selectors'
import { selectActiveHasTracking } from './clubs/clubs.selectors'
import { RapService } from '../rap/rap.service'
import events from '../tagging/events'
import { TaggingService } from '../tagging/tagging.service'
import { TITLE_SERVICE_TRACKING } from '../constants/shared.constants';
import { RouteTypes } from '../main-router.module'
import { DRR_BASE_HREF } from '../../shared/shared.config'
import { ConfigService } from '../config/config.service'
import { SessionService } from '../session/session.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent
  extends AbstractComponent
  implements OnInit, OnDestroy {

  isTrackingInfoAvailable$: Observable<boolean> = this.store$.pipe(
    select(selectIsTrackingInfoAvailable)
  )

  hasAvailableCall$: Observable<boolean> = this.store$.pipe(
    select(selectHasAvailableCall)
  )

  showMenu$: Observable<boolean> = this.store$.pipe(select(selectShowMenu))
  showMenu

  hasTracking$: Observable<boolean> = this.store$.pipe(
    select(selectActiveHasTracking)
  )

  isAgent$: Observable<boolean> = this.store$.pipe(select(selectIsAgent))

  isRapUser$: Observable<boolean> = this.store$.pipe(select(selectIsRapUser))

  callStatusMessage$: Observable<string> = this.store$.pipe(select(selectCallStatusMessage))

  liveTrackingSent = false

  constructor(
    private _callStatusService: CallStatusService,
    private store$: Store<AAAStore>,
    private router: Router,
    protected titleService: Title,
    private rapService: RapService,
    private taggingService: TaggingService,
    private sessionService: SessionService,
    private configService: ConfigService,
    @Inject(DRR_BASE_HREF) private drrBaseHref: string
  ) {
    super()

    this.titleService.setTitle(buildTitle(TITLE_SERVICE_TRACKING(), this.rapService.isRapUser()))
    this.taggingService.setPageEvent(events.dashboard.SERVICE_TRACKING_PAGE_PROMPT, events.dashboard.SERVICE_TRACKING_PAGE_TYPE)
  }

  ngOnInit() {
    this.sessionService.stopSessionTimeout()
    this.subscriptions.push(
      this.hasTracking$.subscribe((hasTracking) => {
        if (hasTracking) {
          // This is necessary if the page is refreshed/reloaded
          this._callStatusService.startCallStatusesUpdater({ retry: true })
        }
      }),
      this.hasAvailableCall$.subscribe((available) => {
        if (!available) {
          this.router.navigate([this.drrBaseHref, RouteTypes.STEPS], {
            queryParams: { step: StepTypes.BREAKDOWN_LOCATION },
          })
        }
      }),
      this.callStatusMessage$.subscribe((message) => {
        if (message === 'REQUEST RECEIVED') {
          this.taggingService.setPageLoadEvent({ pageType: events.dashboard.PAGE_TYPE, pageName: events.dashboard.PAGE_NAME_DEFAULT })
          this.liveTrackingSent = false
        } else if (!this.liveTrackingSent) {
          this.taggingService.setPageLoadEvent({ pageType: events.dashboard.PAGE_TYPE, pageName: events.dashboard.PAGE_NAME_LIVE_TRACKING })
          this.liveTrackingSent = true
        }
      }),
      this.showMenu$.subscribe((show) => {
        this.showMenu = !this.configService.getConfig().nativeAppView && show
      })
    )

    window.scrollTo(0, 0)
  }

  ngOnDestroy(): void {
    super.ngOnDestroy()
    this._callStatusService.stopCallStatusesUpdater()
    this.sessionService.startSessionTimeout()
  }
}
