import { SubmitComponent } from './submit.component'
import { ReviewIssueComponent } from './review-issue/review-issue.component'
import { ReviewAndSubmitComponent } from './submit-step/review-and-submit.component';

export function submitComponents() {
  return [
    SubmitComponent,
    ReviewAndSubmitComponent,
    ReviewIssueComponent,
  ]
}
