import {
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  Input,
  OnInit,
  TemplateRef,
  Type,
  ViewChild
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { FormGroupState } from 'ngrx-forms';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import {
  AUTH,
  AUTH_OEM,
  AUTH_RENTAL,
  AUTH_RESET,
  AUTH_VAS,
  authOemRequest,
  authRentalRequest,
  authVasRequest,
  notifyAuthFailure,
  SET_RAP_AUTH,
  setRapAuthRequest
} from '../../../modules/auth/auth.actions';
import {
  selectAuthState,
  selectIsOdometerConfirmation,
  selectIsRapEligible,
  selectIsSecure,
  selectModeConfiguration
} from '../../../modules/auth/auth.selectors';
import {
  AuthMethods,
  BaseAuthRequestParams,
  OemAuthRequestParams,
  RentalAuthRequestParams,
  SetRapAuthParams,
  VasAuthRequestParams
} from '../../../modules/auth/auth.types';
import { loadModeConfiguration } from '../../../modules/auth/mode-configuration.actions';
import { VendorConfigurationMode } from '../../../modules/auth/mode-configuration.types';
import { VALIDATION_TYPES } from '../../../modules/auth/mode.constants';
import { TaggingService } from '../../../modules/tagging/tagging.service';
import {
  CarRentalAuthFormState,
  OdometerConfirmationFormState,
  SubscriberNumberAuthFormState,
  VinMileageAuthFormState
} from '../../../modules/ui/forms/forms.reducer';
import {
  selectCarRentalAuthForm,
  selectOdometerConfirmationForm,
  selectSubscriberNumberAuthForm,
  selectVinMileageAuthForm
} from '../../../modules/ui/forms/forms.selectors';
import { selectIsLoading } from '../../../modules/ui/loading/loading.selectors';
import {
  animateSplashscreenHide,
  setSplashscreenStep,
  setWatchRecaptchaPosition
} from '../../../modules/ui/ui.actions';
import { AAAStore } from '../../../store/root-reducer';
import { AbstractComponent } from '../../abstract.component';
import { ValidationFormComponent } from '../../types';
import { isMobileApp } from '../../utils/app-detect';
import {
  CarRentalCredentialsComponent
} from '../../whitelabel-credentials-modes/car-rental-credentials/car-rental-credentials.component';
import {
  SubscriberNumberCredentialsComponent
} from '../../whitelabel-credentials-modes/subscriber-number-credentials/subscriber-number-credentials.component';
import { v4 as uuidv4 } from 'uuid';
import { requestVehicleYears } from '../../../modules/vehicle/vehicle.actions';
import { selectDistanceUnit, selectQueryParamsVehicleData } from '../../../modules/ui/ui.selectors';
import { Actions, ofType } from '@ngrx/effects';
import { AuthState } from '../../../modules/auth/auth.reducer';
import { ACTIVE_CALL_STATUS } from '../../../modules/dashboard/calls-statuses/call-status.actions';
import events from '../../../modules/tagging/events';
import { RecaptchaComponent } from 'ng-recaptcha';
import { PrivacyPolicyService } from '../../services/privacy-policy.service';
import { TermsConditionsService } from '../../services/terms-conditions.service';
import { DISTANCE_UNIT } from '../../i18n/i18n.types'
import { convertKmToMiles } from '../../utils/distanceConverter'
import { getCurrentLocale } from '../../i18n/i18n.utils'
import { ConfigService } from '../../../modules/config/config.service'
import { FormModeDirective } from '../white-label-credentials/form-mode.directive';
import {
  OdometerConfirmationV2Component
} from '../../whitelabel-credentials-modes-v2/odometer-confirmation/odometer-confirmation-v2.component';
import {
  VinMileageCredentialsV2Component
} from '../../whitelabel-credentials-modes-v2/vin-mileage-credentials/vin-mileage-credentials-v2.component';

interface ObjectDataInterface {
  auth: Function
  requestData: VasAuthRequestParams | RentalAuthRequestParams | OemAuthRequestParams | SetRapAuthParams
  formComponent: Type<ValidationFormComponent>
}

const _pageType = 'Verification Home'
@Component({
  selector: 'app-white-label-credentials-v2',
  templateUrl: './white-label-credentials-v2.component.html',
  styleUrls: ['./white-label-credentials-v2.component.scss']
})
export class WhiteLabelCredentialsV2Component extends AbstractComponent implements OnInit {
  @Input() mode
  @Input() tabIndexPosition = 0

  @ViewChild('dialogContent', { static: true }) dialogContent: TemplateRef<any>
  @ViewChild('captchaElement') captchaElement: RecaptchaComponent

  @ViewChild(FormModeDirective, { static: true }) formMode!: FormModeDirective;

  form: ComponentRef<ValidationFormComponent>;
  private _formType: string;

  public get formType(): string {
    return this._formType;
  }

  public set formType(value: string) {
    this._formType = value;
  }

  captchaToken: string
  captchaError = false
  siteKey = this.configService.getConfig().googleCaptchaKey

  authParams = new BehaviorSubject<any>({
    referenceId: '',
    captchaToken: null,
    manufacturer: '',
    vin: '',
    odometer: '',
    rentalAgreement: '',
    subscriberId: '',
  })

  oemAuthForm$: Observable<FormGroupState<VinMileageAuthFormState>> = this.store$.pipe(
    select(selectVinMileageAuthForm)
  )

  odometerConfirmationForm$: Observable<FormGroupState<OdometerConfirmationFormState>> = this.store$.pipe(
    select(selectOdometerConfirmationForm)
  )
  isOdometerConfirmation$: Observable<boolean> = this.store$.pipe(
    select(selectIsOdometerConfirmation)
  )

  distanceUnit: DISTANCE_UNIT
  distanceUnit$: Observable<DISTANCE_UNIT> = this.store$.pipe(
    select(selectDistanceUnit)
  )

  rentalAuthForm$: Observable<FormGroupState<CarRentalAuthFormState>> = this.store$.pipe(
    select(selectCarRentalAuthForm)
  )

  vasAuthForm$: Observable<FormGroupState<SubscriberNumberAuthFormState>> = this.store$.pipe(
    select(selectSubscriberNumberAuthForm)
  )

  authOemForm: any = {}
  odometerConfirmationForm: any = {}
  isOdometerConfirmation: boolean
  authRentalForm: any = {}
  authVasForm: any = {}

  get formState(): FormGroupState<any> {
    switch (this.formType?.toUpperCase()) {
      case VALIDATION_TYPES.VAS:
        return this.authVasForm
      case VALIDATION_TYPES.RENTAL:
        return this.authRentalForm
      default:
      case VALIDATION_TYPES.OEM:
        return this.isOdometerConfirmation ? this.odometerConfirmationForm : this.authOemForm
    }
  }

  get controls(): any {
    return this.formState && this.formState.controls
  }

  isSigningIn$: Observable<boolean> = combineLatest([
    this.store$.pipe(select(selectIsLoading(AUTH.ACTION))),
    this.store$.pipe(select(selectIsLoading(AUTH_OEM.ACTION))),
    this.store$.pipe(select(selectIsLoading(AUTH_RENTAL.ACTION))),
    this.store$.pipe(select(selectIsLoading(AUTH_VAS.ACTION))),
    this.store$.pipe(select(selectIsLoading(ACTIVE_CALL_STATUS.ACTION))),
    this.store$.pipe(select(selectIsLoading(SET_RAP_AUTH.ACTION))),
    this.store$.pipe(select(selectIsRapEligible)),
  ]).pipe(map((areActionsLoading) => areActionsLoading.some(Boolean)))

  authState$: Observable<[AuthState, boolean]> = combineLatest([
    this.store$.pipe(select(selectAuthState)),
    this.isSigningIn$,
  ])

  isResetNeeded$: Observable<boolean> = this.actions$.pipe(
    ofType<ReturnType<typeof notifyAuthFailure>>(AUTH_OEM.FAILURE, AUTH_VAS.FAILURE, AUTH_RENTAL.FAILURE, AUTH_RESET),
    map(() => true)
  )

  isSecure$ = this.store$.pipe(select(selectIsSecure))
  modeConfiguration$ = this.store$.pipe(select(selectModeConfiguration))
  queryParamsVehicle$ = this.store$.pipe(select(selectQueryParamsVehicleData))
  captchaInterval = null

  constructor(
    private actions$: Actions,
    private store$: Store<AAAStore>,
    private taggingService: TaggingService,
    public dialog: MatDialog,
    private resolver: ComponentFactoryResolver,
    private privacyPolicy: PrivacyPolicyService,
    private termsConditionsService: TermsConditionsService,
    private configService: ConfigService,
  ) {
    super()
  }

  getObjectDataByType(formType): ObjectDataInterface {
    switch (formType?.toUpperCase()) {
      case VALIDATION_TYPES.VAS:
        return {
          auth: authVasRequest,
          requestData: {
            subscriber: this.formState.value.subscriber,
            referenceId: uuidv4()
          },
          formComponent: SubscriberNumberCredentialsComponent
        }

      case VALIDATION_TYPES.RENTAL:
        return {
          auth: authRentalRequest,
          requestData: {
            refNumber: this.formState.value.refNumber,
            referenceId: uuidv4()
          },
          formComponent: CarRentalCredentialsComponent
        }

      default:
      case VALIDATION_TYPES.OEM:
        return this.isOdometerConfirmation ? {
          auth: setRapAuthRequest,
          requestData: {
            access_token: this.authParams.value.accessToken,
            odometer: this.distanceUnit === DISTANCE_UNIT.KILOMETERS ?
              Math.ceil(convertKmToMiles(this.formState.value.mileage)) :
              this.formState.value.mileage,
          },
          formComponent: OdometerConfirmationV2Component
        } : {
          auth: authOemRequest,
          requestData: {
            vin: this.formState.value.vinNumber,
            mileage: this.distanceUnit === DISTANCE_UNIT.KILOMETERS ?
              Math.ceil(convertKmToMiles(this.formState.value.mileage)) :
              this.formState.value.mileage,
            manufacturer: this.mode,
            referenceId: uuidv4()
          },
          formComponent: VinMileageCredentialsV2Component
        }
    }
  }

  ngOnInit(): void {
    this.modeConfiguration$.subscribe((configData: VendorConfigurationMode) => {
      if (configData) {
        this.formType = configData.validation;
        this.subscriptions.push(
          this.oemAuthForm$.subscribe((form) => {
            this.authOemForm = form
          }),
          this.odometerConfirmationForm$.subscribe((form) => {
            this.odometerConfirmationForm = form
          }),
          this.isOdometerConfirmation$.subscribe((isOdometerConfirmation) => {
            this.isOdometerConfirmation = isOdometerConfirmation
          }),
          this.distanceUnit$.subscribe((distanceUnit) => {
            this.distanceUnit = distanceUnit ? distanceUnit : getCurrentLocale().defaultDistanceUnit
          }),
          this.rentalAuthForm$.subscribe((form) => {
            this.authRentalForm = form
          }),
          this.vasAuthForm$.subscribe((form) => {
            this.authVasForm = form
          }),
        )
        this.displayForm()

        const _action = `${this.formType?.toLocaleUpperCase()} Validation Prompt`
        this.taggingService.setAutomatedEvent(_action, _pageType)
      }
    })

    this.store$.dispatch(loadModeConfiguration({ payload: this.mode }));

    this.queryParamsVehicle$.subscribe((vehicle) => {
      if (vehicle?.year) {
        this.store$.dispatch(requestVehicleYears())
      }
    })

    this.subscriptions.push(
      this.authState$.subscribe(([authState]) => {
        this.authParams.next(authState)
      }),
      this.isSecure$.pipe(filter(isSecure => !isSecure)).subscribe(_ => {
        this.store$.dispatch(animateSplashscreenHide())
      }),
      this.isResetNeeded$
        .pipe(filter((isResetNeeded) => isResetNeeded && this.isOdometerConfirmation))
        .subscribe(() => {
          this.captchaToken = undefined
          this.captchaElement.reset()
        }),
    )
    this.watchRecaptchaPosition()
  }

  pageLoadSent = false
  displayForm() {
    if(this.formType) {
      if(!this.pageLoadSent) {
        this.taggingService.setPageLoadEvent({ pageType: events.auth.PAGE_TYPE, pageName: `${this.formType} ${events.auth.PAGE_NAME_RAP_VERIFICATION}` })
        this.pageLoadSent = true
      }
      const componentFactory = this.resolver.resolveComponentFactory(this.getObjectDataByType(this.formType).formComponent);
      const viewContainerRef = this.formMode.viewContainerRef;
      viewContainerRef.clear();
      this.form = viewContainerRef.createComponent<ValidationFormComponent>(componentFactory);
    }
  }

  doAuth(captchaToken) {
    const { isSecure } = this.authParams.getValue()
    this.store$.dispatch(setSplashscreenStep({ payload: 3 }))
    this.signOn({ isSecure, captchaToken });
    this.stopCaptchaWatcher()
  }

  checkFieldsAndSubmit() {
    if (this.controls?.subscriber) {
      if (!this.controls.subscriber.isValid) {
        this.controls.subscriber.isPristine = false;
        this.controls.subscriber.isDirty = true;
        this.controls.subscriber.isTouched = true;
        (<HTMLInputElement>document.getElementById('subscriber'))?.focus();
      }

      this.handleSubmit()
      return;
    }

    if (!this.isOdometerConfirmation && !this.controls.vinNumber.isValid) {
      this.controls.vinNumber.isPristine = false;
      this.controls.vinNumber.isDirty = true;
      this.controls.vinNumber.isTouched = true;
      (<HTMLInputElement>document.getElementById('vinNumber'))?.focus();
    }

    if (!this.controls.mileage.isValid) {
      this.controls.mileage.isPristine = false;
      this.controls.mileage.isDirty = true;
      this.controls.mileage.isTouched = true;
      (<HTMLInputElement>document.getElementById('mileage'))?.focus();
    }

    this.handleSubmit()
  }

  handleSubmit() {
    this.store$.dispatch(setSplashscreenStep({ payload: 2 }))

    if (!this.isOdometerConfirmation && this.captchaToken === undefined) {
      this.captchaError = true
      return
    }

    if (this.formState.isValid) {
      this.captchaError = false
      this.doAuth(this.captchaToken)
      return
    }
  }

  handleCaptchaSuccess(captchaToken: string) {
    this.captchaToken = captchaToken
    this.captchaError = false
    this.stopCaptchaWatcher()
  }

  signOn({ isSecure = false, captchaToken = null }: BaseAuthRequestParams) {
    const requestData = this.getObjectDataByType(this.formType).requestData
    const authRequest = this.getObjectDataByType(this.formType).auth
    const TYPE = this.formType?.toUpperCase();
    const _action = `${TYPE} Validation Request`;
    const payload = {
      isSecure,
      method: AuthMethods[TYPE],
      captchaToken,
      ...requestData
    }

    this.taggingService.setClickEvent(_action, _pageType)
    this.store$.dispatch(
      authRequest({ payload })
    )
  }

  // continuously check captcha position and place it on
  // the middle of the screen
  watchRecaptchaPosition() {
    this.store$.dispatch(
      setWatchRecaptchaPosition({ payload: true })
    )
  }

  stopCaptchaWatcher() {
    this.store$.dispatch(
      setWatchRecaptchaPosition({ payload: false })
    )
  }

  openPrivacyPolicy() {
    const _action = 'Privacy Policy'
    this.taggingService.setClickEvent(_action, _pageType)
    this.privacyPolicy.open()
  }

  openTerms() {
    const _action = 'Terms and Conditions'
    this.taggingService.setClickEvent(_action, _pageType)
    this.termsConditionsService.open()
  }

  isWebApp() {
    return !isMobileApp()
  }

  ngOnDestroy() {
    this.form?.destroy();
  }

}
