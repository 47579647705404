<ng-container [ngSwitch]="markerDetails.type">
  <icon-breakdown-location-pin *ngSwitchCase="'BREAKDOWN'"></icon-breakdown-location-pin>
  <icon-tow-location-pin *ngSwitchCase="'TOW_LOCATION'"></icon-tow-location-pin>
  <icon-truck-location-pin *ngSwitchCase="'TRUCK'" [type]="markerDetails.truckType"></icon-truck-location-pin>
  <aaa-icon *ngSwitchCase="MarkerTypes.CAR" name="drr-car-pin" [color]="markerDetails.active ? 'tertiary' : 'dark'" size="x-large"></aaa-icon>
  <aaa-icon *ngSwitchCase="MarkerTypes.AAA_OWNED" name="drr-aaa-pin" [color]="markerDetails.active ? 'tertiary' : 'primary'" size="x-large"></aaa-icon>
  <aaa-icon *ngSwitchCase="MarkerTypes.AAA_APPROVED" name="drr-wrench-pin" [color]="markerDetails.active ? 'tertiary' : 'dark'" size="x-large"></aaa-icon>
  <aaa-icon *ngSwitchCase="MarkerTypes.EV_STATION" name="drr-bolt-pin" [color]="markerDetails.active ? 'tertiary' : 'dark'" size="x-large"></aaa-icon>
  <aaa-icon *ngSwitchCase="MarkerTypes.CUSTOM" name="drr-place-pin" [color]="markerDetails.active ? 'tertiary' : 'dark'" size="x-large"></aaa-icon>
  <aaa-icon *ngSwitchCase="MarkerTypes.TOW_TRUCK" name="drr-truck-pin" color="tertiary" size="x-large"></aaa-icon>
</ng-container>
