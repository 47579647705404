<ion-card class="ion-no-margin ion-margin-vertical">
  <div class="ion-padding card-container">
    <div>
      <aaa-text size="title3" color="dark" align="center" i18n>Set your location</aaa-text>
      <aaa-text size="caption" class="margin-bottom-8" color="medium" align="center" i18n>Drag map to move pin</aaa-text>
    </div>
    <ion-item lines="none" class="ion-no-padding">
      <app-location-address [address]="address"></app-location-address>
      <aaa-button class="close-button" variant="ghost" size="small" (click)="handleClearAddress()" [disabled]="loading" *ngIf="onClearAddress.observers.length > 0">
        <aaa-icon name="close" slot="end" color="dark" size="small"></aaa-icon>
      </aaa-button>
    </ion-item>
    <aaa-button
      appCtaClickEvent="Confirm Location Card"
      class="confirm-button margin-top-8"
      expand="block"
      i18n
      (click)="handleConfirm()"
      [disabled]="adjustRequired"
      [loading]="loading"
    >
      Confirm Location
    </aaa-button>
  </div>
</ion-card>
