import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { CredentialEntryComponent } from '../credential-entry/credential-entry.component';
import { AAAStore } from '../../../store/root-reducer';
import { I18N_SUPPORTED_APP_IDS } from '../../../shared/i18n/i18n.utils';
import { ConfigService } from '../../config/config.service';
import { OEMBranding } from '../auth.types';
import { RapService } from '../../rap/rap.service';


@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
})
export class SignInComponent extends CredentialEntryComponent {

  localeSwitcherEnabled = false
  nativeAppView = false

  constructor(
    protected store$: Store<AAAStore>,
    protected rapService: RapService,
    protected configService: ConfigService,
  ) {
    super(store$)
    this.localeSwitcherEnabled = I18N_SUPPORTED_APP_IDS.includes(this.rapService.getRapAppId() as OEMBranding)
    this.nativeAppView = this.configService.getConfig().nativeAppView
  }

}
