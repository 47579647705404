<ng-container *ngIf="isRedesign else old">
  <app-menu />
  <div class="redesigned-wizard" [ngClass]="(bgContrast$ | async) ? 'bg-contrast' : 'bg-default'">
    <div class="ion-page" [ngSwitch]="(editingStep$ | async)?.url">
      <ng-container *ngSwitchCase="'location'">
        <app-breakdown-location-step class="step"></app-breakdown-location-step>
      </ng-container>
      <ng-container *ngSwitchCase="'issue'">
        <app-issue-step class="step"></app-issue-step>
      </ng-container>
      <ng-container *ngSwitchCase="'vehicle'">
        <app-vehicle-step class="step"></app-vehicle-step>
      </ng-container>
      <ng-container *ngSwitchCase="'submit'">
        <app-review-and-submit class="step"></app-review-and-submit>
      </ng-container>
      <ng-container *ngSwitchCase="'tow-location'">
        <app-tow-location-step class="step"></app-tow-location-step>
      </ng-container>
    </div>
  </div>
</ng-container>
<ng-template #old>
  <section class="content-container">

    <ng-container *ngIf="(steps$ | async) as steps">
      <nav class="nav-section">
        <app-stepper [steps$]="steps$"> </app-stepper>
      </nav>
    </ng-container>

    <div role="complementary" class="mobile-wizard-container">
      <div id="wizard-content"  class="wizard-container" [ngSwitch]="(editingStep$ | async)?.url">
        <ng-container *ngSwitchCase="'location'">
          <app-location></app-location>
        </ng-container>
        <ng-container *ngSwitchCase="'issue'">
          <app-issue></app-issue>
        </ng-container>
        <ng-container *ngSwitchCase="'vehicle'">
          <app-vehicle></app-vehicle>
        </ng-container>
        <ng-container *ngSwitchCase="'submit'">
          <app-submit></app-submit>
        </ng-container>
        <ng-container *ngSwitchCase="'tow-location'">
          <app-split-panel [noPadding]="true" [showSecondaryOnMobile]="false">
            <app-tow-location-shop-list-top primary></app-tow-location-shop-list-top>
          </app-split-panel>
        </ng-container>
      </div>
    </div>
  </section>
</ng-template>
