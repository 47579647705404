import { Inject, Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { ROUTER_NAVIGATED } from '@ngrx/router-store'
import { catchError, filter, map, mergeMap, switchMap, tap, withLatestFrom, } from 'rxjs/operators'
import { IndexedCollection, PayloadedAction } from '../../shared/types'
import {
  AUTOMATED_VEHICLE_ANALYTICS,
  clearEditMetadata,
  CREATE_NEW_VEHICLE,
  EDIT_VEHICLE,
  notifySetVehicleDataFailure,
  notifyVehicleMakesFailure,
  notifyVehicleModelsFailure,
  notifyVehicleModelTypesFailure,
  notifyVehicleYearsFailure,
  requestVehicleByPlate,
  requestVehicleImages,
  SET_VEHICLE_DETAILS,
  SET_VEHICLE_MAKE,
  SET_VEHICLE_MODEL_AND_TYPE,
  SET_VEHICLE_YEAR,
  setIsVehicleEditing,
  setVehicleStep,
  VEHICLE_BY_PLATE,
  VEHICLE_IMAGES,
  VEHICLE_MAKES,
  VEHICLE_MODEL_TYPES,
  VEHICLE_MODELS,
  VEHICLE_YEARS,
  vehicleByPlateSuccess,
  vehicleImagesSuccess,
  vehicleMakesSuccess,
  vehicleModelsSuccess,
  vehicleModelTypesSuccess,
  vehicleYearsSuccess,
} from './vehicle.actions'
import { Params, Router } from '@angular/router'
import {
  ASSIGN_ELIGIBILITY_VEHICLE,
  ASSIGN_EXISTING_VEHICLE,
  SET_VEHICLE,
  setEligibilityVehicle,
  setVehicle,
} from '../member/member.actions'
import { VehicleService } from './vehicle.service'
import { from, of } from 'rxjs'
import {
  EditVehicleParams,
  ModelType,
  PlateToVinResponse,
  VehicleEditMetadata,
  VehicleMakes,
  VehicleModels,
  VehicleYears,
} from './vehicle.types'
import { AAAStore } from '../../store/root-reducer'
import { select, Store } from '@ngrx/store'
import { selectEditMetadata, selectWorkingVehicle } from './vehicle.selectors'
import { VehicleEditState } from './vehicle.reducer'
import { VehicleData } from '../member/member.types'
import { selectUrl } from '../../store/router.selectors'
import { WizardService } from '../wizard/wizard.service'
import { currentEditStepLocation } from '../wizard/wizard.selectors'
import { selectNeedsTow } from '../issue/issue.selectors'
import { StepTypes, SubmitSections } from '../ui/ui.types'
import { ErrorReportingService } from '../../shared/services/error-reporting.service'
import { selectActiveCallStatus } from '../dashboard/calls-statuses/call-status.selectors'
import { TaggingService } from '../tagging/tagging.service'
import events from '../tagging/events'
import { selectEligibility, selectIsVehicleChangeAllowed } from '../auth/auth.selectors'
import { PAGE_NAME_MAPPING } from './vehicle.utils';
import { AdobeEventTypes } from '../tagging/tagging.types'
import { AdobeEventService } from '../tagging/adobe/event-adobe.service'
import { selectIsMotorcycleEligible, selectIsMotorhomeEligible } from '../member/member.selectors'
import { RouteTypes } from '../main-router.module';
import { DRR_BASE_HREF } from '../../shared/shared.config';

const NEXT_DESTINATIONS = {
  [CREATE_NEW_VEHICLE]: { step: 'vehicle', section: 'makes' },
  [SET_VEHICLE_YEAR]: { step: 'vehicle', section: 'makes' },
  [SET_VEHICLE_MAKE]: { step: 'vehicle', section: 'models' },
  [SET_VEHICLE_MODEL_AND_TYPE]: { step: 'vehicle', section: 'details' },
  [SET_VEHICLE_DETAILS]: { step: 'submit' },
}

@Injectable()
export class VehicleEffects {
  constructor(
    private actions$: Actions,
    private store$: Store<AAAStore>,
    private router: Router,
    private _vehicleService: VehicleService,
    private errorReportingService: ErrorReportingService,
    private wizardService: WizardService,
    private taggingService: TaggingService,
    private adobeEventService: AdobeEventService,
    @Inject(DRR_BASE_HREF) private drrBaseHref: string
  ) {}

  handleNavigation = createEffect(() =>
    this.actions$.pipe(
      ofType(ROUTER_NAVIGATED),
      map(
        (action: PayloadedAction) => action.payload.routerState.root.queryParams
      ),
      filter((params: Params) => params.step === 'vehicle'),
      map(({section}) => {
        const pageName = PAGE_NAME_MAPPING[section] || events.vehicle.PAGE_NAME_VEHICLE_LIST
        this.taggingService.setPageLoadEvent({ pageType: events.vehicle.PAGE_TYPE, pageName })
        return setVehicleStep({payload: {step: section || ''}})
      })
    )
  )

  handleVehicleEdit = createEffect(() =>
    this.actions$.pipe(
      ofType(EDIT_VEHICLE),
      switchMap((action: PayloadedAction<EditVehicleParams>) => {
        this.router.navigate([this.drrBaseHref, RouteTypes.STEPS], {
          queryParams: {
            step: StepTypes.VEHICLE,
            section: action.payload.section,
          },
        })
        return of(setIsVehicleEditing({ payload: Boolean(action.payload.vehicle) }))
      })
    )
  )

  loadVehicleYears$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VEHICLE_YEARS.REQUEST),
      switchMap(() =>
        from(this._vehicleService.getYears()).pipe(
          map((years: VehicleYears) => vehicleYearsSuccess({ payload: years })),
          catchError((error) =>
            this.errorReportingService.notifyErrorObservable(
              error,
              notifyVehicleYearsFailure
            )
          )
        )
      )
    )
  )

  loadVehicleMakes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VEHICLE_MAKES.REQUEST),
      withLatestFrom(
        this.store$.pipe(select(selectWorkingVehicle)),
        this.store$.pipe(select(selectIsMotorcycleEligible)),
        this.store$.pipe(select(selectIsMotorhomeEligible))
      ),
      switchMap(
        ([action, workingVehicle, isMotorcycleEligible, isMotorhomeEligible]: [PayloadedAction, VehicleEditState, boolean, boolean]) =>
          from(
            this._vehicleService.getMakes(
              +(action.payload || workingVehicle.year), {
                showMotorcycles: isMotorcycleEligible,
                showMotorhomes: isMotorhomeEligible,
                showTypicalVehicles: true
              }
            )
          ).pipe(
            map((makes: VehicleMakes) =>
              vehicleMakesSuccess({ payload: makes })
            ),
            catchError((error) =>
              this.errorReportingService.notifyErrorObservable(
                error,
                notifyVehicleMakesFailure
              )
            )
          )
      )
    )
  )

  loadVehicleModels$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VEHICLE_MODELS.REQUEST),
      withLatestFrom(
        this.store$.pipe(select(selectWorkingVehicle)),
        this.store$.pipe(select(selectIsMotorcycleEligible)),
        this.store$.pipe(select(selectIsMotorhomeEligible))
      ),
      switchMap(
        ([
          action, workingVehicle, isMotorcycleEligible, isMotorhomeEligible
        ]: [
          PayloadedAction, VehicleEditState, boolean, boolean
        ]) => {
          const payload = {
            ...workingVehicle,
            ...action.payload,
          }

          return from(
            this._vehicleService.getModels(+payload.year, payload.make, {
              showMotorcycles: isMotorcycleEligible,
              showMotorhomes: isMotorhomeEligible,
              showTypicalVehicles: true
            })
          ).pipe(
            map((models: VehicleModels) =>
              vehicleModelsSuccess({ payload: models })
            ),
            catchError((error) =>
              this.errorReportingService.notifyErrorObservable(
                error,
                notifyVehicleModelsFailure
              )
            )
          )
        }
      )
    )
  )

  loadVehicleTypes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VEHICLE_MODEL_TYPES.REQUEST),
      switchMap((action: PayloadedAction) =>
        from(
          this._vehicleService.getModelTypes(action.payload)
        ).pipe(
          map((modelTypes: IndexedCollection<ModelType[]>) =>
            vehicleModelTypesSuccess({ payload: modelTypes })
          ),
          catchError((error) =>
            this.errorReportingService.notifyErrorObservable(
              error,
              notifyVehicleModelTypesFailure
            )
          )
        ))
    )
  )

  getVehicleByPlate$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof requestVehicleByPlate>>(VEHICLE_BY_PLATE.REQUEST),
      switchMap(({payload: { tag, state }}) =>
        from(this._vehicleService.getVehicle(tag, state)).pipe(
          switchMap((response: PlateToVinResponse) => ([
            requestVehicleImages({payload: response.vehicles}),
            vehicleByPlateSuccess({payload: response})
          ])
          ),
          catchError((error) =>
            this.errorReportingService.notifyErrorObservable(
              error,
              [vehicleByPlateSuccess({payload: { vehicles: [] }})]
            )
          )
        )
      )
    )
  )

  getVehiclesImages$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof requestVehicleImages>>(VEHICLE_IMAGES.REQUEST),
      switchMap(({payload: vehicles}) =>
        from(Promise.all(vehicles.map(async (vehicle) => {
          const vehicleImages = await this._vehicleService.getVehicleImages(vehicle.year, vehicle.make, vehicle.model)
          return {...vehicle, images: vehicleImages.vehicleImages}
        }))).pipe(
          switchMap((response) => ([vehicleImagesSuccess({payload: response})])),
          catchError((error) =>
            this.errorReportingService.notifyErrorObservable(
              error,
              [vehicleImagesSuccess({payload: vehicles})]
            )
          )
        )
      )
    )
  )

  // We have an existing vehicle to assign to the call.  This just tags it in and moves on.
  assignExistingVehicle$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ASSIGN_EXISTING_VEHICLE),
        switchMap((action) =>
          of(action).pipe(
            withLatestFrom(
              this.store$.pipe(select(selectActiveCallStatus)),
              this.store$.pipe(select(currentEditStepLocation)),
              this.store$.pipe(select(selectNeedsTow))
            ),
            filter(([_, activeCallStatus]) => activeCallStatus === null),
            map(([_, _2, currentStepUrl, needsTow]) => {
              // if we came from any edition (right panel) we will redirect the user
              // to the page whom triggered the edition
              if (currentStepUrl && !needsTow) {
                return this.wizardService.backToEditUrl(currentStepUrl)
              }

              // If we didn't trigger the edition, but instead we manually select something
              // from the wizard, we will go to towing only when is needed if not, will go back to summary
              const stepsection = needsTow ? StepTypes.TOWING : StepTypes.SUBMIT
              const params = {
                step: stepsection,
                section: SubmitSections.SUMMARY,
              }

              if (needsTow) {
                delete params['section']
              }

              this.router.navigate([], { queryParams: params })
            }),
            catchError((error) =>
              from(this.errorReportingService.notifyError(error))
            )
          )
        )
      ),
    { dispatch: false }
  )

  assignEligibilityVehicle$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ASSIGN_ELIGIBILITY_VEHICLE),
        withLatestFrom(this.store$.select(selectEligibility)),
        filter(([_, eligibility]) =>
          eligibility?.contractIdentityData?.['vehicle']),
        map(([_, eligibility]) =>
          setEligibilityVehicle({payload: eligibility.contractIdentityData['vehicle']})),
      )
  )

  // This effect handles edits - once data is stored, this determines whether we are in the middle
  // of editing a vehicle, and if so, where to go when done.
  setVehicleData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        CREATE_NEW_VEHICLE,
        SET_VEHICLE_YEAR,
        SET_VEHICLE_MAKE,
        SET_VEHICLE_MODEL_AND_TYPE,
        SET_VEHICLE_DETAILS
      ),
      switchMap((action) =>
        of(action).pipe(
          withLatestFrom(
            this.store$.pipe(select(selectEditMetadata)),
            this.store$.pipe(select(selectUrl))
          ),
          filter(
            ([payloadedAction, metadata, currentUrl]: [
              PayloadedAction,
              VehicleEditMetadata,
              string
            ]) =>
              this.navigationNextLocation(
                payloadedAction.type,
                metadata.completionUrl,
                currentUrl
              )
          ),
          withLatestFrom(this.store$.pipe(select(selectWorkingVehicle))),
          mergeMap(([_, vehicleEditState]: [never, VehicleEditState]) => {
            const actions: Array<any> = []

            // Sometimes we are editing an existing vehicle with an ID.  At other times we are "editing"
            // an earlier stage of a vehicle which is still new.
            if (vehicleEditState && vehicleEditState.id) {
              actions.push(
                setVehicle({ payload: vehicleEditState as VehicleData })
              )
            }

            return actions
          }),
          catchError((error) =>
            this.errorReportingService.notifyErrorObservable(
              error,
              notifySetVehicleDataFailure
            )
          )
        )
      )
    )
  )

  // This effect complements the above setVehicleData$, which takes care of most saving/routing -
  // this effect is needed if setVehicleDetails is carried out in main flow, rather than by a vehicle edit.
  setVehicleDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SET_VEHICLE_DETAILS),
      withLatestFrom(
        this.store$.pipe(select(selectWorkingVehicle)),
        this.store$.pipe(select(selectEditMetadata))
      ),
      // If a return URL is set, SetVehicle is taken care of by the main flow
      filter(
        ([_, _2, metadata]: [never, VehicleEditState, VehicleEditMetadata]) =>
          !metadata.completionUrl
      ),
      map(([_, workingVehicle, _2]: [never, VehicleEditState, never]) =>
        setVehicle({ payload: workingVehicle as VehicleData })
      )
    )
  )

  /**
   * We need to check if the vehicle information has been set before
   * redirecting the user to the towing view, this will avoid
   * a conflict between the steps state
   */
  handleSetVehicle$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SET_VEHICLE),
        withLatestFrom(this.store$.pipe(select(selectEditMetadata))),
        // If a return URL is set, SetVehicle is taken care of by the main flow
        filter(
          ([_, metadata]: [PayloadedAction, VehicleEditMetadata]) =>
            !metadata.completionUrl
        ),
        withLatestFrom(this.store$.pipe(select(selectNeedsTow))),
        tap(([_, needsTow]) => {
          const queryParams = {
            step: needsTow ? StepTypes.TOWING : StepTypes.SUBMIT,
          }
          this.router.navigate([], { queryParams })
        })
      ),
    { dispatch: false }
  )

  // If the user navigates away, forget about editing - don't want later vehicle changes to
  // navigate weirdly
  clearEditOnCancel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ROUTER_NAVIGATED),
      map(
        (action: PayloadedAction) => action.payload.routerState.root.queryParams
      ),
      filter(
        (params: Params) => params.step !== StepTypes.VEHICLE || !params.section
      ),
      withLatestFrom(this.store$.pipe(select(selectEditMetadata))),
      filter(
        ([_, metadata]: [PayloadedAction, VehicleEditMetadata]) =>
          !!metadata.completionUrl
      ),
      map(clearEditMetadata)
    )
  )

  simulateVehicleAnalytics$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AUTOMATED_VEHICLE_ANALYTICS),
      withLatestFrom(this.store$.pipe(select(selectIsVehicleChangeAllowed))),
      filter(([_, isVehicleChangeAllowed]: [PayloadedAction, boolean]) => !isVehicleChangeAllowed),
      tap((_) => {
        this.taggingService.setAutomatedEvent(
          events.vehicle.VEHICLE_PRELOADED_SELECT,
          events.vehicle.VEHICLE_SUSPICIOUS_PAGE_TYPE
        )
        this.taggingService.setAutomatedEvent(
          events.vehicle.VEHICLE_COMPLETE,
          events.vehicle.VEHICLE_SUSPICIOUS_PAGE_TYPE
        )
        this.adobeEventService.sendEvent({
          eventName: AdobeEventTypes.CTA,
          eventValue: events.vehicle.VEHICLE_PRELOADED_SELECT
        })
        this.adobeEventService.sendEvent({
          eventName: AdobeEventTypes.CTA,
          eventValue: events.vehicle.VEHICLE_COMPLETE
        })
      })
    ),
  { dispatch: false }
  )

  navigationNextLocation(action, completionUrl, currentUrl = null): boolean {
    // Ugly special case for makes...
    // 1. Don't navigate "back" if back is the same as the current location...
    if (
      completionUrl &&
      completionUrl !== currentUrl &&
      action !== SET_VEHICLE_MAKE
    ) {
      this.router.navigateByUrl(completionUrl)
      return true
    }

    const queryParams = NEXT_DESTINATIONS[action]

    this.router.navigate([], { queryParams })
    // 2. But if we are finishing the edit, still tell the caller
    return completionUrl === currentUrl
  }
}
