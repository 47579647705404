import { Component } from '@angular/core';
import { isRedesign } from '../../shared/utils/cookies';


@Component({
  template: `
    <ng-container *ngIf="isRedesign else old">
      <app-service-tracking class="ion-page"></app-service-tracking>
    </ng-container>
    <ng-template #old>
      <app-dashboard></app-dashboard>
    </ng-template>
  `
})
export class ServiceTrackingStarterComponent {

  isRedesign = isRedesign()

}
