import { StepperComponent } from './stepper/stepper.component'
import { StepComponent } from './stepper/step.component'
import { AuthSecurityWrapperComponent } from '../modules/auth/auth-security-wrapper/auth-security-wrapper.component'

import { PanelComponent } from './layouts/panel/panel.component'
import { SplitPanelComponent } from './layouts/split-panel/split-panel.component'
import { ExpandingPanelComponent } from './layouts/expanding-panel/expanding-panel.component'
import { SummaryComponent } from './summary/summary.component'
import { SplashscreenComponent } from './splashscreen/splashscreen.component'

import { AgentLoginComponent } from './layouts/agent-login/agent-login.component'

import { MinimalCredentialsComponent } from '../modules/auth/minimal-credentials/minimal-credentials.component'
import { EntitlementInfoComponent } from './entitlement-info/entitlement-info.component'
import { FormErrorComponent } from './controls/form-error/form-error.component'
import { BannerComponent } from './banner/banner.component'
import { ButtonComponent } from './controls/button/button.component'
import { CancelRequestButtonComponent } from './controls/cancel-request/cancel-request.component'
import { CheckboxComponent } from './controls/checkbox/checkbox.component'
import { ClickableLinkComponent } from './controls/clickable-link/clickable-link.component'
import { DraggerComponent } from './controls/dragger/dragger.component'
import { ExpandingInputComponent } from './controls/expanding-input/expanding-input.component'
import { HomeLocationButtonComponent } from './controls/home-location-button/home-location-button.component'
import { ImageComponent } from './controls/image/image.component'
import { LinkButtonComponent } from './controls/link-button/link-button.component'
import { RadioSquareComponent } from './controls/radio-square/radio-square.component'
import { SelectOrTextComponent } from './controls/select-or-text/select-or-text.component'
import { WhiteLabelCredentialsComponent } from './layouts/white-label-credentials/white-label-credentials.component'
import {
  VinMileageCredentialsComponent
} from './whitelabel-credentials-modes/vin-mileage-credentials/vin-mileage-credentials.component'
import {
  CarRentalCredentialsComponent
} from './whitelabel-credentials-modes/car-rental-credentials/car-rental-credentials.component'
import {
  SubscriberNumberCredentialsComponent
} from './whitelabel-credentials-modes/subscriber-number-credentials/subscriber-number-credentials.component'
import { SelectComponent } from './controls/select/select.component'
import { AriaLinkButtonComponent } from './controls/aria-link/aria-link-button.component'
import { SkipToContentComponent } from './controls/skip-to-content/skip-to-content.component'
import { WelcomeHeaderComponent } from './welcome-header/welcome-header.component'
import { LegalFooterComponent } from './legal-footer/legal-footer.component'
import {
  ThirdPartyCookiesInstructionsComponent
} from './third-party-cookies-instructions/third-party-cookies-instructions.component'
import { LoginFailAssistantComponent } from '../modules/auth/login-fail-assitant/login-fail-assistant.component'
import { MembershipNumberComponent } from '../modules/auth/login-forms/membership-number.component';
import { MemberNameComponent } from '../modules/auth/login-forms/member-name.component';
import { PhoneNumberComponent } from '../modules/auth/login-forms/phone-number.component';
import { ReviewCardComponent } from '../modules/location/aar/review/review-card.component'
import { ReviewListComponent } from '../modules/location/aar/review/review-list.component'
import { ReviewsCountComponent } from './reviews-count/reviews-count.component'
import { TowLocationSelectedComponent } from '../modules/location/tow-location-selected/tow-location-selected.component'
import { GoogleGeocodeService } from '../modules/location/google-geocode/google-geocode.service'
import { ChargingLevelComponent } from '../modules/location/ev-station/charging-level/charging-level.component';
import {
  ChargingLevelListComponent
} from '../modules/location/ev-station/charging-level-list/charging-level-list.component';
import { EvStationNotesComponent } from '../modules/location/ev-station/station-notes/station-notes.component';
import { ElectricVehicleComponent } from '../modules/location/ev-station/electric-vehicle/electric-vehicle.component'
import { EvStationConnectorTypeComponent } from '../modules/location/ev-station/connector-type/connector-type.component'
import {
  EvStationConnectorTypeListComponent
} from '../modules/location/ev-station/connector-type-list/connector-type-list.component'
import { StepperProgressBarComponent } from './stepper-progress-bar/stepper-progress-bar.component'
import { BumpOutAnimationComponent } from './stepper-progress-bar/bump-out-animation/bump-out-animation.component'
import { TowLocationPromoV2Component } from '../modules/location/tow-location-promo-v2/tow-location-promo-v2.component'
import { FacilityStatusComponent } from './facility-status/facility-status.component';
import { FacilityNextDateComponent } from './facility-next-date/facility-next-date.component';
import { SeeMoreButtonComponent } from './see-more-button/see-more-button.component';
import { LogoutComponent } from '../modules/auth/logout/logout.component';
import { AbTestSwitcherComponent } from './ab-test-switcher/ab-test-switcher.component'
import { TowLocationBannerComponent } from '../modules/location/tow-location-banner/tow-location-banner.component'
import { InputLicensePlateComponent } from './controls/input-license-plate/input-license-plate.component'
import { InputUsStateComponent } from './controls/input-us-state/input-us-state.component'
import { InputVehicleColorComponent } from './controls/input-vehicle-color/input-vehicle-color.component'
import { RECAPTCHA_LOADER_OPTIONS } from 'ng-recaptcha';
import { LOCALE_ID } from '@angular/core';
import { LocaleSelectorComponent } from './i18n/locale-selector/locale-selector.component'
import { UnitSystemSelectorComponent } from './i18n/unit-system-selector/unit-system-selector.component'
import {
  OdometerConfirmationComponent
} from './whitelabel-credentials-modes/odometer-confirmation/odometer-confirmation.component'
import {
  MultipleRequestsButtonComponent
} from '../modules/dashboard/multiple-requests-button/multiple-requests-button.component'
import { HeaderComponent } from './layouts/header/header.component'
import { ClosableHeaderComponent } from './layouts/closable-header/closable-header.component'
import { provideNgxMask } from 'ngx-mask';
import { MemberCredentialsComponent } from '../modules/auth/member-credentials/member-credentials.component'
import { LoginLiteComponent } from '../modules/auth/login-forms/login-lite.component';
import { MemberNumberComponent } from '../modules/auth/login-forms/member-number.component';
import { FormHelperComponent } from './controls/form-helper/form-helper.component';
import { LocationCardComponent } from '../modules/breakdown-location/location-card/location-card.component'
import { HeaderRedesignComponent } from './layouts/header-redesign/header/header.component'
import {
  LocationAddressComponent
} from '../modules/breakdown-location/components/location-address/location-address.component'
import { LetsStartSheetComponent } from '../modules/location/lets-start-sheet/lets-start-sheet.component';
import {
  LocationDetailsSheetComponent
} from '../modules/breakdown-location/components/location-details-sheet/location-details-sheet.component'
import { ConfigService } from '../modules/config/config.service'
import { ALLOY_INSTANCE, createAlloyInstance } from '../modules/tagging/adobe/alloy.factory'
import {
  TowLocationDiscountBannerComponent
} from '../modules/location/tow-location-discount-banner/tow-location-discount-banner.component'
import { PhoneNumberLinkComponent } from './phone-number/phone-number-link.component'
import { DestinationTypeComponent } from '../modules/location/destination-type/destination-type.component'
import { ChargingLevelV2Component } from '../modules/location/ev-station/charging-level-v2/charging-level-v2.component'
import {
  ChargingLevelListV2Component
} from '../modules/location/ev-station/charging-level-list-v2/charging-level-list-v2.component'
import { ShopCardComponent } from '../modules/location/shop-card/shop-card.component'
import { ShopDetailsModalComponent } from '../modules/ui/dialog/prompts/shop-details-modal/shop-details-modal.component'
import { ReviewsCountV2Component } from './reviews-count-v2/reviews-count-v2.component'
import {
  CustomDestinationCardComponent
} from '../modules/location/custom-destination-card/custom-destination-card.component'
import { AlphabetScrollListComponent } from './alphabet-scroll-list/alphabet-scroll-list.component'
import { LogoComponent } from './layouts/header-redesign/logo/logo.component'
import {
  WhiteLabelCredentialsV2Component
} from './layouts/white-label-credentials-v2/white-label-credentials-v2.component';
import {
  VinMileageCredentialsV2Component
} from './whitelabel-credentials-modes-v2/vin-mileage-credentials/vin-mileage-credentials-v2.component';
import {
  OdometerConfirmationV2Component
} from './whitelabel-credentials-modes-v2/odometer-confirmation/odometer-confirmation-v2.component';

export function sharedComponents() {
  return [
    StepperComponent,
    StepComponent,
    AuthSecurityWrapperComponent,
    ExpandingPanelComponent,
    PanelComponent,
    SplitPanelComponent,
    SummaryComponent,
    SplashscreenComponent,
    MinimalCredentialsComponent,
    MemberCredentialsComponent,
    MembershipNumberComponent,
    MemberNumberComponent,
    MemberNameComponent,
    LoginLiteComponent,
    PhoneNumberComponent,
    EntitlementInfoComponent,
    FacilityNextDateComponent,
    FacilityStatusComponent,
    FormErrorComponent,
    FormHelperComponent,
    BannerComponent,
    ButtonComponent,
    TowLocationPromoV2Component,
    DraggerComponent,
    ExpandingInputComponent,
    LinkButtonComponent,
    ClickableLinkComponent,
    HomeLocationButtonComponent,
    RadioSquareComponent,
    CheckboxComponent,
    SelectOrTextComponent,
    CancelRequestButtonComponent,
    AgentLoginComponent,
    WhiteLabelCredentialsComponent,
    WhiteLabelCredentialsV2Component,
    VinMileageCredentialsComponent,
    OdometerConfirmationComponent,
    CarRentalCredentialsComponent,
    SubscriberNumberCredentialsComponent,
    SelectComponent,
    AriaLinkButtonComponent,
    SkipToContentComponent,
    WelcomeHeaderComponent,
    LegalFooterComponent,
    LoginFailAssistantComponent,
    ThirdPartyCookiesInstructionsComponent,
    ReviewCardComponent,
    ReviewListComponent,
    ReviewsCountComponent,
    ReviewsCountV2Component,
    TowLocationSelectedComponent,
    ChargingLevelComponent,
    ChargingLevelV2Component,
    ChargingLevelListComponent,
    ChargingLevelListV2Component,
    EvStationNotesComponent,
    ElectricVehicleComponent,
    EvStationConnectorTypeComponent,
    EvStationConnectorTypeListComponent,
    SeeMoreButtonComponent,
    StepperProgressBarComponent,
    BumpOutAnimationComponent,
    LogoutComponent,
    AbTestSwitcherComponent,
    TowLocationBannerComponent,
    InputLicensePlateComponent,
    InputUsStateComponent,
    InputVehicleColorComponent,
    LocaleSelectorComponent,
    UnitSystemSelectorComponent,
    MultipleRequestsButtonComponent,
    HeaderComponent,
    LocationCardComponent,
    LocationAddressComponent,
    LetsStartSheetComponent,
    LocationDetailsSheetComponent,
    TowLocationDiscountBannerComponent,
    PhoneNumberLinkComponent,
    DestinationTypeComponent,
    ShopCardComponent,
    ShopDetailsModalComponent,
    CustomDestinationCardComponent,
    AlphabetScrollListComponent,
    VinMileageCredentialsV2Component,
    OdometerConfirmationV2Component,
  ]
}

export function sharedProviders() {
  return [
    provideNgxMask(),
    GoogleGeocodeService,
    [
      {
        provide: RECAPTCHA_LOADER_OPTIONS,
        useFactory: () => ({
          onBeforeLoad(url) {
            url.searchParams.set('hl', localStorage.getItem('locale'))
            return { url }
          },
        }),
        deps: [LOCALE_ID],
      },
    ],
    {
      provide: ALLOY_INSTANCE,
      useFactory: createAlloyInstance,
      deps: [ConfigService]
    },
  ]
}

export function sharedModules() {
  return [
    HeaderRedesignComponent,
    ClosableHeaderComponent,
    LogoComponent,
    ImageComponent,
  ]
}
