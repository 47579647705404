import { createAction } from '@ngrx/store'

export const CANCEL_REQUEST = 'CANCEL_REQUEST'
export const CANCEL_EDITING_REQUEST = 'CANCEL_EDITING_REQUEST'
export const RESET_STATE = 'RESET_STATE'

// Cancels an active call
export const cancelRequest = createAction(CANCEL_REQUEST)

// Cancels the call which the user is currently editing
export const cancelEditingRequest = createAction(CANCEL_EDITING_REQUEST,
  (payload: { redirect: boolean } = { redirect : true }) => ({payload}))

export const resetState = createAction(RESET_STATE)
