@if (canCancel$ | async) {
  <ion-grid class="ion-no-padding">
    <ion-row class="ion-justify-content-center ion-align-items-center">
      @if (canceling) {
        <ion-col size="auto" class="ion-margin-horizontal">
          <aaa-text i18n color="dark" size="body">Cancel?</aaa-text>
        </ion-col>
        <ion-col size="auto" class="ion-margin-horizontal">
          <aaa-button i18n
                      class="cancel-yes-button"
                      variant="accent"
                      (click)="confirmCancel()"
                      tabIndex="2"
                      size="small">YES</aaa-button>
        </ion-col>
        <ion-col size="auto">
          <aaa-button i18n
                      class="cancel-no-button"
                      variant="outline"
                      (click)="setCanceling(false)"
                      tabIndex="3"
                      size="small">NO</aaa-button>
        </ion-col>
      } @else {
        <ion-col>
          <aaa-button
            class="cancel-button"
            variant="outline"
            expand="block"
            (click)="setCanceling(true)"
            [disabled]="isCallRequesting$ | async"
            [loading]="isCallCanceling$ | async"
            i18n
            i18n-aria-label
            aria-label="Cancel Request">Cancel Request</aaa-button>
        </ion-col>
      }
    </ion-row>
  </ion-grid>
}
