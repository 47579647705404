import { VehicleComponent } from './vehicle.component'
import { SelectVehicleComponent } from './select-vehicle/select-vehicle.component'
import { VehicleSummaryComponent } from './vehicle-summary/vehicle-summary.component'
import { CreateVehicleComponent } from './create-vehicle/create-vehicle.component'
import { MakesComponent } from './makes/makes.component'
import { ModelsComponent } from './models/models.component'
import { ColorComponent } from './color/color.component'
import { VehicleDetailsComponent } from './vehicle-details/vehicle-details.component'
import { DetailsEntryComponent } from './details-entry/details-entry.component'
import { VehicleEditOptionsComponent } from './vehicle-edit-options/vehicle-edit-options.component'
import { EditTagComponent } from './edit-tag/edit-tag.component'
import { VehicleHeaderComponent } from './vehicle-header/vehicle-header.component'
import { VehicleYearsComponent } from './vehicle-years/vehicle-years.component'
import { AddPlateComponent } from './add-plate/add-plate.component'
import { VehicleConfirmComponent } from './vehicle-confirm/vehicle-confirm.component'
import { VehicleStepComponent } from './vehicle-step/vehicle-step.component'
import { SelectVehicleStepComponent } from './select-vehicle-step/select-vehicle-step.component'
import { CreateVehicleStepComponent } from './create-vehicle-step/create-vehicle-step.component'
import { MakesStepComponent } from './makes-step/makes-step.component'
import { ModelsStepComponent } from './models-step/models-step.component'
import { VehicleDetailsStepComponent } from './vehicle-details-step/vehicle-details-step.component'
import { ColorStepComponent } from './color-step/color-step.component'
import { VehicleHeaderStepComponent } from './vehicle-header-step/vehicle-header-step.component'
import { VehicleSummaryStepComponent } from './vehicle-summary-step/vehicle-summary-step.component'
import { VehicleEditOptionsStepComponent } from './vehicle-edit-options-step/vehicle-edit-options-step.component'
import { VehicleYearsStepComponent } from './vehicle-years-step/vehicle-years-step.component'
import { EditButtonComponent } from './edit-button/edit-button.component'
import { EditVehicleOptionsComponent } from './edit-vehicle-options/edit-vehicle-options.component'
import { VehicleMakeLogoComponent } from './vehicle-make-logo/vehicle-make-logo.component'
import { VehicleMakeTilesComponent } from './vehicle-make-tiles/vehicle-make-tiles.component';

export function vehicleComponents() {
  return [
    ColorComponent,
    CreateVehicleComponent,
    DetailsEntryComponent,
    MakesComponent,
    ModelsComponent,
    SelectVehicleComponent,
    VehicleComponent,
    VehicleDetailsComponent,
    VehicleHeaderComponent,
    VehicleSummaryComponent,
    VehicleEditOptionsComponent,
    VehicleYearsComponent,
    EditTagComponent,
    EditButtonComponent,
    AddPlateComponent,
    VehicleConfirmComponent,
    VehicleStepComponent,
    SelectVehicleStepComponent,
    CreateVehicleStepComponent,
    MakesStepComponent,
    ModelsStepComponent,
    VehicleDetailsStepComponent,
    ColorStepComponent,
    VehicleHeaderStepComponent,
    VehicleSummaryStepComponent,
    VehicleEditOptionsStepComponent,
    EditVehicleOptionsComponent,
  ]
}

export function vehicleModules() {
  return [
    VehicleYearsStepComponent,
    VehicleMakeLogoComponent,
    VehicleMakeTilesComponent,
  ]
}
