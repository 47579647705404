@if (isListStep$ | async) {
  <app-header-redesign>
    <app-back-button slot="start" (aaaClick)="navigateBack()" />
    <ion-title>
      <app-logo />
    </ion-title>
    <app-closable-header slot="end" />
    <app-stepper-redesign />
  </app-header-redesign>
}

<ion-content [fullscreen]="(isListStep$ | async)">
  <ng-template #list>
    <ion-grid class="ion-no-padding">
      <ion-row class="ion-margin-horizontal ion-margin-top">
        <ion-col>
          <aaa-text i18n size="title3" color="text" weight="bold">
            Please select a tow destination
          </aaa-text>
        </ion-col>
      </ion-row>
      @if (allowCustomTowDestination) {
        <ion-row class="margin-4">
          <ion-col>
            <ng-container *ngTemplateOutlet="searchDestination; context: { $implicit: { useShadow: false } }"></ng-container>
          </ion-col>
        </ion-row>
      }
      @if (!isRapUser && !isEV) {
        <ion-row class="ion-padding-horizontal">
          <ion-col>
            <app-tow-location-discount-banner></app-tow-location-discount-banner>
          </ion-col>
        </ion-row>
      }
      <ion-row class="ion-margin ion-align-items-center">
        <ion-col>
          <aaa-text i18n weight="bold">Available Facilities</aaa-text>
        </ion-col>
        <ion-col>
          <aaa-button appCtaClickEvent="View On Map" class="ion-no-margin ion-float-end" size="medium" variant="outline" (click)="showMap(true)">
            <ng-container i18n>View On Map</ng-container> <aaa-icon name="map" size="small"></aaa-icon>
          </aaa-button>
        </ion-col>
      </ion-row>
      <ion-row class="ion-padding-horizontal">
        <ion-col>
          <ng-container *ngIf="!isRapUser && !isEV">
            <app-shop-preferences
              *ngIf="shopPreferences$ | async as preferences"
              [hasRatings]="preferences.hasRatings"
              [order]="preferences.order"
              [openOnly]="preferences.openOnly"
              [sorting]="preferences.sorting"
              (filter)="handleFilter($event)"
              (sort)="handleSorting($event)"
            ></app-shop-preferences>
          </ng-container>
        </ion-col>
      </ion-row>
      <ion-row class="ion-padding-horizontal">
        <!-- No Facilities / Loading -->
        <ng-container *ngIf="isLoading$ | async">
          <div class="tow-location-body_no-results">
            <app-location-skeleton-item [items]="5"></app-location-skeleton-item>
          </div>
        </ng-container>

        <!-- No Shops / Custom message -->
        <ng-container *ngIf="(facilitiesDisplay$ | async)?.aarData.length && (aarsCustomMessage$ | async)">
          <div i18n class="tow-location-body_no-results">
            Please verify the selected shop services your vehicle
          </div>
        </ng-container>

        <!-- Facilities -->
        <ion-col>
          <ng-container *ngIf="(facilitiesDisplay$ | async)?.aarData.length &&  !(isLoading$ | async)">
            <ng-container *ngFor="let aar of (facilitiesDisplay$ | async).aarData; trackBy: trackFacilitiesByFn">
              <app-shop-card
                [destination]="aar"
                (onCardClick)="handleDestinationCardClick(aar)"
                [borderShadow]="false"
                [rap]="isRapUser"
              >
              </app-shop-card>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="!(facilitiesDisplay$ | async)?.aarData.length &&  !(isLoading$ | async)">
            <p i18n>No Nearby Facilities</p>
          </ng-container>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ng-template>
  <ng-container *ngIf="isMapStep$ | async; else list">
  <div class="map-container">
    <app-map
      [preventTouch]="false"
      [mapCenter]="center$ | async"
      [customMarkers]="markers$ | async"
      [fitMarkers]="true"
      (mapDrag)="handleMapDrag($event)"
      [isLoading]="(isLoadingSearchArea$ | async)"
      [mapBoundsPadding]="mapBoundsPadding$ | async"
    >
      <div topSlot>
        <ion-grid class="ion-no-padding top-slot">
          <ion-row class="ion-align-items-center padding-vertical">
            <ion-col size="auto">
              <aaa-button appCtaClickEvent="Back To Tow List" class="back-button" variant="ghost" size="medium" (click)="showMap(false)">
                <aaa-icon name="chevron-back-outline"></aaa-icon>
              </aaa-button>
            </ion-col>
            <ion-col>
              @if (allowCustomTowDestination) {
                <ng-container *ngTemplateOutlet="searchDestination; context: { $implicit: { useShadow: true, hasBackButton: true } }"></ng-container>
              }
            </ion-col>
          </ion-row>
          <ion-row class="ion-justify-content-center">
            <ion-col size="6">
              <aaa-button
                expand="block"
                size="medium"
                [loading]="isLoadingSearchArea$ | async"
                *ngIf="displaySearchArea || (isLoadingSearchArea$ | async)"
                (click)="handleSearchArea()"
                variant="on-map">
                <aaa-icon *ngIf="!(isLoadingSearchArea$ | async)" name="search-outline" size="small"></aaa-icon>
                <ng-container *ngIf="(isLoadingSearchArea$ | async) else searchText" i18n>Loading facilities</ng-container>
                <ng-template #searchText i18n >Search this area</ng-template>
              </aaa-button>
            </ion-col>
          </ion-row>
        </ion-grid>
      </div>
      <div bottomSlot>
        <ng-container *ngIf="(aarPreviewLocation$ | async); else customDestination">
          <app-shop-card
            *ngIf="(aarPreviewLocation$ | async) as destination"
            [destination]="destination"
            [rap]="isRapUser"
            (close)="handleClearSelectedShop()"
          >
            <div footer>
              <ion-row>
                <ion-col size="auto" class="padding-vertical-4">
                  <aaa-button variant="secondary" expand="block" size="medium" (click)="handleDetails(destination)" i18n>Details</aaa-button>
                </ion-col>
                <ion-col class="padding-vertical-4">
                  <aaa-button size="medium" expand="block" (click)="handleSelectDestination(destination)" i18n>Tow to Facility</aaa-button>
                </ion-col>
              </ion-row>
            </div>
          </app-shop-card>
        </ng-container>
        <ng-template #customDestination>
          <app-custom-destination-card
            *ngIf="towLocation"
            [loading]="isLoading$ | async"
            [location]="towLocation"
            (onUseThisLocation)="handleNext()"
            (onClearLocation)="handleAddressSelected(null)"
          ></app-custom-destination-card>
        </ng-template>
      </div>
    </app-map>
  </div>
</ng-container>
</ion-content>

<ng-template #searchDestination let-params>
  <app-location-search
    i18n-placeholder
    placeholder="Search Location"
    [useShadow]="params.useShadow"
    [hasBackButton]="params.hasBackButton"
    [lastSearchLocation]="lastSearchLocation$ | async"
    [suggestedDestination]="suggestedShop$ | async"
    [rap]="isRapUser"
    (locationSelected)="handleCustomAddressSelected($event)"
    (suggestedDestinationClick)="handleDestinationCardClick($event, true)"
  ></app-location-search>
</ng-template>

<app-passengers
  [isOpen]="(step$ | async) === 'passengers'"
  (select)="selectPassengers($event)"
  (close)="closePassengers()"
></app-passengers>

<ng-container *ngIf="isModalVisible">
  <app-shop-details-modal (onCloseModal)="handleCloseModal()" [id]="facilityId"></app-shop-details-modal>
</ng-container>
