 <ng-container *ngIf="isRedesign else old">
    <router-outlet></router-outlet>
  </ng-container>
  <ng-template #old>
    <app-skip-to-content [elementId]="targetElementId"> </app-skip-to-content>

    <div class="aaa" [ngClass]="{'hide': (isTransitioning$ | async)}">
      <app-header [scrollTop]="scrollTop"></app-header>
      <main
        role="main"
        class="app"
        [ngClass]="{'without-header': !showHeader}"
      >
        <div class="container">
          <router-outlet></router-outlet>
        </div>
      </main>
    </div>
  </ng-template>
  <app-splashscreen
    [hidden]="(isSplashscreenHidden$ | async)"
    [step]="(step$ | async)"
  >
  </app-splashscreen>
  <app-dialog-invoker></app-dialog-invoker>
