import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges} from '@angular/core'
import {
  PaceSetterDetailGroup,
  PaceSetterList,
  PaceSetterCode,
  PaceSetterDetailCategory,
} from '../issue.types'
import { select, Store } from '@ngrx/store'
import { AAAStore } from '../../../store/root-reducer'
import { PaceSetterSituation } from '../issue.types'
import {
  setPaceSetterOptions,
  setPaceSetterOptionsAdditionalRequirement,
} from '../issue.actions'
import { TaggingService } from '../../tagging/tagging.service'
import { Title } from '@angular/platform-browser'
import { AbstractComponent } from '../../../shared/abstract.component'
import events from '../../tagging/events'
import { selectIsRapUser } from '../../auth/auth.selectors'
import { isEmpty, get } from 'lodash'
import { AdobeEventService } from '../../tagging/adobe/event-adobe.service'
import { AdobeEventTypes } from '../../tagging/tagging.types'

@Component({
  selector: 'app-situation-details-v2',
  templateUrl: './situation-details-v2.component.html',
  styleUrls: ['./situation-details-v2.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SituationDetailsV2Component extends AbstractComponent implements OnChanges {
  @Input() isOpen = false
  @Input() options: PaceSetterDetailGroup = null
  @Input() activeSelections: PaceSetterList[]
  @Input() groupIndex: number
  @Input() tabIndexPosition = 0
  @Input() activeSituation: PaceSetterSituation

  additionalRequirements: PaceSetterDetailCategory

  constructor(
    private store$: Store<AAAStore>,
    private tagging: TaggingService,
    private adobeEventService: AdobeEventService,
    protected titleService: Title
  ) {
    super()
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.options) {
      this.additionalRequirements =
        get(changes.options.currentValue, 'additionalRequirements') || {}
    }
  }

  isRapUser$ = this.store$.pipe(select(selectIsRapUser))

  detailSelectionChanged(
    groupIndex: number,
    listIndex: number,
    selection: PaceSetterCode[]
  ) {
    this.setClickEvent(listIndex, selection)
    // Dispatch selection change
    this.store$.dispatch(
      setPaceSetterOptions({ groupIndex, listIndex, selection })
    )

    const _selection = selection[listIndex] || {} as any
    if(!_selection.needAdditionalRequirement) {
      this.additionalRequirementsChanged(this.groupIndex, [])
    }
  }

  additionalRequirementsChanged(
    groupIndex: number,
    selection: PaceSetterCode[]
  ) {
    this.store$.dispatch(
      setPaceSetterOptionsAdditionalRequirement({
        groupIndex,
        listIndex: 0,
        selection,
      })
    )
  }

  private setClickEvent(listIndex: number, selection: PaceSetterCode[]) {
    const _selection = selection[listIndex]
    const _name = get(_selection, 'name')

    if (isEmpty(_name)) {
      return
    }

    const label = _selection['tagLabel']
      ? `${_selection['tagLabel']} ${_selection['name']}`
      : _selection['name']
    const _action = `${label} - ${_selection['paceSetterCode']}`

    this.tagging.setClickEvent(_action, events.issue.ISSUE_DETAILS_PAGE_TYPE)

    const actionData = this.adobeEventService.mapSubIssueSelectionEvent(
      _selection['paceSetterOverrideKey'],
      _selection['paceSetterCode']
    )

    this.adobeEventService.sendEvent({
      eventValue: actionData.cta_value,
      eventName: AdobeEventTypes.CTA
    }, actionData.issue_type, actionData)
  }

  hasAdditionalRequirements() {
    return this.activeSelections?.[0]?.members?.[0]?.needAdditionalRequirement
  }
}
