import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  TemplateRef,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { Store } from '@ngrx/store';
import { isEmpty } from 'lodash';
import { fromEvent, Subject } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';
import { AbstractResponsiveComponent } from '../../../shared/abstract-responsive.component';
import { AAAStore } from '../../../store/root-reducer';

import events from '../../tagging/events';
import { TaggingService } from '../../tagging/tagging.service';
import { AutocompleteComponent } from '../../ui/autocomplete/auto-complete.component';
import { SearchViews, USE_CURRENT_LOCATION } from '../location.types';
import { AdobeEventTypes } from '../../tagging/tagging.types';
import { AdobeEventService } from '../../tagging/adobe/event-adobe.service';
import { formatAddress } from '../location.utils'

@Component({
  selector: 'app-location-auto-complete',
  templateUrl: './location-auto-complete.component.html',
  styleUrls: ['./location-auto-complete.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LocationAutocompleteComponent extends AbstractResponsiveComponent {
  @ViewChild('locationAutoContainer', { static: false })
  locationAutoContainer: ElementRef<HTMLDivElement>
  @ViewChild('autocomplete', { static: false }) autocomplete: AutocompleteComponent

  @Input() tabIndex = 0
  @Input() isLoading = false
  @Input() hasUseCurrentLocationOption = false
  @Input() customElement = false
  @Input() lastSearch = {}
  @Input() view: SearchViews = SearchViews.BREAKDOWN_LOCATION

  @Input() model = ''
  @Input() placeholder = ''
  @Input() readonly = false
  @Input() focused = false
  @Input() suggestedShopTemplate: TemplateRef<any>;
  @Input() isGpsLocationDenied = false
  @Output() addressSelected = new Subject()
  @Output() isValidChange = new Subject()
  @Output() currentLocation: any = new Subject()
  @Output() openMapModal: EventEmitter<any> = new EventEmitter<any>()
  @Output() onBackButton: EventEmitter<any> = new EventEmitter<any>()

  _clicked = false
  _scrolled = false

  constructor(
    private taggingService: TaggingService,
    private store$: Store<AAAStore>,
    private adobeEventService: AdobeEventService
  ) {
    super()
  }

  @Input() pinIconColor: 'red' | 'green' = 'red'

  set clicked(val) {
    this._clicked = val
    setTimeout(() => {
      this._clicked = false
    }, 300)
  }

  get clicked() {
    return this._clicked
  }

  @HostListener('click', ['$event'])
  onClick(e) {
    if (!this.isMobile) {
      return
    }

    if (!this.clicked && !this.isLoading) {
      this?.focus()
    }

    const classNames = e.target.classList
    if (classNames.contains('fullscreen')) {
      this.focused = false
      this._scrolled = false
      this.scrollClasses('remove')
    }
  }

  ngOnInit() {
    const scrollListener = fromEvent(window, 'scroll').pipe(
      debounceTime(300), // emits once, then ignores subsequent emissions for 300ms, repeat...
      filter(() => this.isMobile && this.focused)
    )

    this.subscriptions.push(
      scrollListener.subscribe(() => this.scrollToTop()),
    )
  }

  handleAddressSelected(result) {
    if (result.custom_value && result.custom_value === USE_CURRENT_LOCATION) {
      this.currentLocation.next(true)
    } else {
      this.addressSelected.next(result)
    }

    if (!isEmpty(result)) {
      this.resetActions()
    } else {
      this.adobeEventService.sendEvent({
        eventValue: events.location.LOCATION_CLEAR_INPUT,
        eventName: AdobeEventTypes.CTA
      })
      this.taggingService.setClickEvent(
        events.location.LOCATION_CLEAR_INPUT,
        events.location.LOCATION_PAGE_TYPE
      )
    }
  }

  handleIsValidChange(result) {
    this.isValidChange.next(result)
  }

  focus() {
    if (!this.focused) {
      const pageType = this.view === SearchViews.BREAKDOWN_LOCATION ? events.location.PAGE_TYPE : events.towTo.PAGE_TYPE
      this.taggingService.setPageLoadEvent({ pageType, pageName: events.location.PAGE_NAME_ENTER_ADDRESS })
    }
    this.focused = true
    this.scrollToTop()
    this.scrollClasses()
    this.autocomplete?.focus()
  }

  handleFocus(focused) {
    this.focused = focused

    if (focused) {
      this?.focus()
    }
  }

  resetActions() {
    this.clicked = true
    this.focused = false
    this._scrolled = false
    this.scrollClasses('remove')
  }

  cancelSearch() {
    this.resetActions()
    this.onBackButton.emit()
  }

  scrollToTop() {
    window.scrollTo(0, 0)
  }

  formatAddress() {
    const address = formatAddress(this.model)
    if (!address) {
      return ''
    }
    return `${address.main_text} </br> ${address.secondary_text}`
  }

  scrollClasses(type = 'add') {
    const root = document.getElementsByTagName('html')[0]

    switch (type) {
      case 'add':
        this.isMobile ?? root.classList.add('scroll-block')
        break
      case 'remove':
        root.classList.remove('scroll-block')
    }
  }
}
