import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core'
import { AAAStore } from '../../../store/root-reducer'
import { select, Store } from '@ngrx/store'
import { selectEditOrAddVehicleDesc, selectVehicleMakes } from '../vehicle.selectors'
import { Observable, of } from 'rxjs'
import { requestVehicleMakes, setVehicleMake, VEHICLE_MAKES } from '../vehicle.actions'
import { TaggingService } from '../../tagging/tagging.service'
import { AbstractComponent } from '../../../shared/abstract.component'
import { selectIsLoading } from '../../ui/loading/loading.selectors'
import events from '../../tagging/events'
import { NgSelectComponent } from '@ng-select/ng-select'
import { GENERIC_MAKES, Make } from '../vehicle.types'
import { CommonMakes, ExtraMakes } from '../vehicle.contants'
import { MembershipLevelsSettings } from '../../member/member.types';
import {
  selectIsMotorcycleEligible,
  selectIsMotorhomeEligible,
  selectMemberShipLevel
} from '../../member/member.selectors';
import { AdobeEventTypes } from '../../tagging/tagging.types'
import { AdobeEventService } from '../../tagging/adobe/event-adobe.service'

@Component({
  selector: 'app-makes',
  templateUrl: './makes.component.html',
  styleUrls: ['./makes.component.scss']
})
export class MakesComponent extends AbstractComponent implements OnInit {

  @ViewChild('makesSelect', { static: false }) makesSelect: NgSelectComponent
  @ViewChild('makesContainer', { static: false }) makesContainer: ElementRef
  @Input() tabIndexPosition = 0

  commonMakes: Make[] = CommonMakes

  isLoading$: Observable<any> = this.store$.pipe(
    select(selectIsLoading(VEHICLE_MAKES.ACTION))
  )

  makes: string[] = []

  makes$: Observable<string[]> = this.store$.pipe(select(selectVehicleMakes))

  notListedMakes$: Observable<string[]>

  membershipLevel$: Observable<MembershipLevelsSettings> = this.store$.pipe(select(selectMemberShipLevel))
  membershipLevel: MembershipLevelsSettings

  isMotorcycleEligible$: Observable<boolean> = this.store$.pipe(select(selectIsMotorcycleEligible))
  isMotorcycleEligible: boolean

  isMotorhomeEligible$: Observable<boolean> = this.store$.pipe(select(selectIsMotorhomeEligible))
  isMotorhomeEligible: boolean

  editOrAddVehicleDesc$ = this.store$.pipe(select(selectEditOrAddVehicleDesc))
  editOrAddVehicleDesc: string

  constructor(
    private store$: Store<AAAStore>,
    private taggingService: TaggingService,
    private adobeEventService: AdobeEventService
  ) {
    super()
  }

  ngOnInit() {
    this.store$.dispatch(
      // This action defaults to the currently-selected year
      requestVehicleMakes({ payload: null })
    )

    this.subscriptions.push(
      this.membershipLevel$.subscribe((level) => this.membershipLevel = level),
      this.isMotorhomeEligible$.subscribe((eligible) => this.isMotorhomeEligible = eligible),
      this.isMotorcycleEligible$.subscribe((eligible) => this.isMotorcycleEligible = eligible),
      this.makes$.subscribe((makes) => {
        this.makes = makes?.filter((make) => (
          make.toLowerCase() !== GENERIC_MAKES.MOTORHOME_RV || this.isMotorhomeEligible
        ) && (
          make.toLowerCase() !== GENERIC_MAKES.MOTORCYCLE || this.isMotorcycleEligible
        )
        )

        // map common and non-common makes
        const mappedMakes = this.makes.reduce((prev, cur) => {
          if (cur && CommonMakes.map(make => make.label.toUpperCase()).includes(cur.toUpperCase())) {
            prev.common.push(cur)
          } else {
            prev.nonCommon.push(cur)
          }
          return prev
        }, {common: [], nonCommon: []})

        // search for makes that are in the ExtraMakes array
        const extraMakes = ExtraMakes.reduce((prev, cur) => {
          if (cur && this.makes.map(make => make.toUpperCase()).includes(cur.label.toUpperCase())) {
            prev.push(cur.label)
          }
          return prev
        }, [])

        // move extraMakes to the common makes array
        let extraMakesToCommon = CommonMakes.length - mappedMakes.common.length
        extraMakes.forEach((cur) => {
          if (extraMakesToCommon <= 0) {
            return
          }
          let nonCommonIndex = mappedMakes.nonCommon.indexOf(cur)
          mappedMakes.common.push(mappedMakes.nonCommon.splice(nonCommonIndex, 1)[0])
          extraMakesToCommon--
        })

        // moves non-common makes from not listed to the tiles, in case there available
        let nonCommonSlots = CommonMakes.length - mappedMakes.common.length
        while (nonCommonSlots > 0 && mappedMakes.nonCommon.length > 0) {
          mappedMakes.common.push(mappedMakes.nonCommon.splice(0, 1)[0])
          nonCommonSlots--
        }
        this.notListedMakes$ = of(mappedMakes.nonCommon)
        // normalizing data structure for common and non-common makes
        this.commonMakes = mappedMakes.common.map(make =>
          CommonMakes.find(
            commonMake => commonMake.label.toUpperCase() === make?.toUpperCase()
          )
          ||
          ExtraMakes.find( // search for icons on extraMakes array
            extraMake => extraMake.label.toUpperCase() === make?.toUpperCase()
          )
          ||
          { label: make } // default to only label when not a common make
        )
        // auto select if only one make available
        if (this.makes.length === 1) {
          this.makeChange(this.makes[0])
        }
      }),
      this.editOrAddVehicleDesc$.subscribe(
        editOrAdd => this.editOrAddVehicleDesc = editOrAdd
      )
    )
  }

  ngAfterViewInit() {
    this.focusFirstElement()
  }

  makeChange(make) {
    this.adobeEventService.sendEvent({
      eventName: AdobeEventTypes.CTA,
      eventValue: `${this.editOrAddVehicleDesc} ${events.vehicle.VEHICLE_MAKE_SELECT}`
    })

    this.taggingService.setClickEvent(
      events.vehicle.VEHICLE_MAKE_SELECT,
      events.vehicle.VEHICLE_PAGE_TYPE
    )
    this.store$.dispatch(setVehicleMake({ payload: make }))
  }

  private focusFirstElement() {
    const container = this.makesContainer.nativeElement
    const childs = container.getElementsByClassName('makes_tile')

    if (childs && childs.length) {
      childs[0].focus()
    }
  }

}
