import { Component, ViewEncapsulation } from '@angular/core'
import { select, Store } from '@ngrx/store'
import { Observable } from 'rxjs/internal/Observable'
import { AAAStore } from '../../store/root-reducer'
import { Step } from '../ui/ui.types'
import {
  selectEditingStep,
  selectIsBgContrast,
  selectIterableSteps,
} from '../ui/ui.selectors'
import { AbstractResponsiveComponent } from '../../shared/abstract-responsive.component'
import { WizardService } from './wizard.service'
import { removeVehicleStep } from '../ui/ui.actions'
import { filter } from 'rxjs/operators'

@Component({
  styleUrls: ['./wizard.component.scss'],
  templateUrl: './wizard.component.html',
  selector: 'app-wizard',
  encapsulation: ViewEncapsulation.None,
})
export class WizardComponent extends AbstractResponsiveComponent {
  steps$: Observable<Step[]> = this.store$.pipe(select(selectIterableSteps))

  editingStep$: Observable<Step> = this.store$.pipe(select(selectEditingStep))

  bgContrast$: Observable<boolean> = this.store$.pipe(
    select(selectIsBgContrast)
  )

  constructor(
    private store$: Store<AAAStore>,
    private wizardService: WizardService
  ) {
    super()
  }

  ngOnInit() {
    this.subscriptions.push(
      this.wizardService.isVehicleChangeAllowed$
        .pipe(filter((isVehicleChangeAllowed) => !isVehicleChangeAllowed))
        .subscribe(() => this.store$.dispatch(removeVehicleStep()))
    )
  }
}
