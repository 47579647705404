<ng-container *ngIf="template?.detailGroups[(activeStep$ | async) - 1] as details">
  <aaa-modal
    i18n-title
    i18n-subtitle
    [isOpen]="isOpen"
    [initialBreakpoint]="1"
    [breakpoints]="[0, 1]"
    contentMode="fit"
    [title]="details.heading"
    [subtitle]="details.subHeading"
    (aaaBreakpointDidChange)="close.emit()"
    (aaaModalDidDismiss)="close.emit()"
    (close)="close.emit()"
  >
    <div class="modal-container ion-margin-bottom">
      <app-situation-details-v2
        [isOpen]="isOpen"
        [options]="details"
        [activeSelections]="activeSelections$ | async"
        [activeSituation]="activeSituation$ | async"
        [groupIndex]="(activeStep$ | async) - 1"
        [tabIndexPosition]="103"
      >
      </app-situation-details-v2>
      <aaa-button
        class="next-button"
        expand="block"
        type="submit"
        *ngIf="(activeSituationName$ | async) as activeSituationName"
        [disabled]="!hasActivePaceSetterSelection()"
        (click)="handleNext($event, activeSituationName)"
        [tabIndex]="105"
        i18n>
        Next
        <aaa-icon name="arrow-right"></aaa-icon>
      </aaa-button>
    </div>
  </aaa-modal>
</ng-container>
