import {
  FUEL_TYPES,
  PACE_SETTER_SITUATION_ADDITIONAL_REQUIREMENTS_TYPES,
  PACE_SETTER_SITUATION_CODES,
  PACE_SETTER_SITUATION_TYPES,
  PaceSetterDetails,
  PaceSetterOptions,
  PaceSetterSituation,
} from './issue.types'
import { IndexedCollection } from '../../shared/types'
import { PACE_SETTER_OVERRIDE_TYPES } from '../servicing-club/servicing-club.types'
import issue from '../tagging/events/issue';
import { FUEL_TYPE_ELECTRIC } from '../vehicle/vehicle.utils';

const LABEL_ACCIDENT_OPT = () => $localize`Accident`
const LABEL_STUCK_OPT = () => $localize`Vehicle Stuck`
const LABEL_BATTERY_ISSUE_OPT = () => $localize`Battery Test or Replace`
const LABEL_FLAT_TIRE_OPT = () => $localize`Flat Tire`
const LABEL_LOCKED_OUT_OPT = () => $localize`Locked Out`
const LABEL_OUT_OF_FUEL_OPT = () => $localize`Fuel/EV Charge`
const LABEL_NEED_TOW_OPT = () => $localize`Need a tow`
const LABEL_CAR_WONT_START_OPT = () => $localize`My Car Won't Start`
const LABEL_SERVICE_REQUEST_OPT = () => $localize`Service Request`
const HEADER_TIRE = () => $localize`Do you have spares for each flat tire?`
const NOTES_TIRE = () => $localize`Tell us about your issue, and we'll be better prepared when we arrive.`
const LIST_VALUES_YES_TIRE = () => $localize`Yes`
const FLAT_TIRE_HAVE_SPARE = () => $localize`I have a spare`
const LIST_VALUES_NO_TIRE = () => $localize`No`
const FLAT_TIRE_DONT_HAVE_SPARE = () => $localize`I don't have a spare`
const HEADER_WONT_START = () => $localize`What's happening?`
const LIST_VALUES_DIED_WONT_START = () => $localize`Vehicle died while driving`
export const LIST_VALUES_NOTHING_WONT_START = () => $localize`I turn the key and nothing happens`
export const LIST_VALUES_CLICKING_WONT_START = () => $localize`I turn the key and hear a clicking sound`
const LIST_VALUES_CRANKS_WONT_START = () => $localize`I turn the key and my car cranks, but does not start`
export const LIST_VALUES_BATTERY_WONT_START = () => $localize`Battery issue`
const NOTES_WONT_START = () => $localize`Is the check engine light on?  Can you see anything else that might help us diagnose the issue?`
const HEADER_LOCKED = () => $localize`Where are the keys?`
const HEADER_ADD_REQUIREMENTS_LOCKED = () => $localize`I have a high priority situation`
const LIST_VALUES_IN_VEHICLE_LOCKED = () => $localize`Locked in the vehicle`
const LIST_VALUES_BROKEN_LOST_LOCKED = () => $localize`My key is broken or lost`
const LIST_VALUES_WONT_TURN_LOCKED = () => $localize`My key won't turn in the ignition`
const LIST_VALUES_WONT_UNLOCK_LOCKED = () => $localize`My key won't unlock the door`
const LIST_VALUES_IDK_LOCKED = () => $localize`I don't know`
const LIST_VALUES_LOVED_ONE_LOCKED = () => $localize`A loved one is locked in the car`
const LIST_VALUES_MEDICATION_LOCKED = () => $localize`My medication is locked in the car`
const HEADER_FUEL = () => $localize`Please select the fuel type for your vehicle.`
const SUB_HEADER_FUEL = () => $localize`Additional fees may apply depending on membership level.`
const LIST_VALUES_GAS_FUEL = () => $localize`Gasoline`
const LIST_VALUES_DIESEL_FUEL = () => $localize`Diesel Fuel`
const LIST_VALUES_ELECTRIC_FUEL = () => $localize`Electric Vehicle`
const LIST_VALUES_HYDRO_FUEL = () => $localize`Hydrogen/Alternate`
const HEADER_STUCK = () => $localize`We are sorry to hear your vehicle is stuck.`
const SUB_HEADER_STUCK = () => $localize`Will your vehicle require a tow after it has been freed and back on the roadway?`
const LIST_VALUES_YES_STUCK = () => $localize`Yes, my vehicle will need to be towed to another location.`
const LIST_VALUES_NO_STUCK = () => $localize`No, I don't need a tow`

export function constructDetailsSummary(details: PaceSetterDetails, isRapUser: boolean) {
  if (!details) {
    return ''
  }

  let additionalDetails = ''
  const selectedGroups = details.selectedGroups
  if (selectedGroups.length && selectedGroups[0].lists.length) {
    const firstList = selectedGroups[0].lists[0]
    if (firstList.members.length) {
      additionalDetails = firstList.members
        .map((detail) => detail.alternateLabel || detail.label)
        .join(', ')
    }
  }

  const name = isRapUser
    ? details.label.replace('Battery Test or Replace', 'Battery Issue')
    : details.label
  if (additionalDetails) {
    return [name, additionalDetails].join(': ')
  } else {
    return name
  }
}

const paceSetterMap = {
  [PACE_SETTER_SITUATION_CODES.T906]: PACE_SETTER_SITUATION_TYPES.ACCIDENT,
  [PACE_SETTER_SITUATION_CODES.T932]: PACE_SETTER_SITUATION_TYPES.STUCK,
  [PACE_SETTER_SITUATION_CODES.L302]: PACE_SETTER_SITUATION_TYPES.CAR_WONT_START,
  [PACE_SETTER_SITUATION_CODES.T680]: PACE_SETTER_SITUATION_TYPES.CAR_WONT_START,
  [PACE_SETTER_SITUATION_CODES.L701]: PACE_SETTER_SITUATION_TYPES.LOCKED_OUT,
  [PACE_SETTER_SITUATION_CODES.L890]: PACE_SETTER_SITUATION_TYPES.LOCKED_OUT,
  [PACE_SETTER_SITUATION_CODES.L801]: PACE_SETTER_SITUATION_TYPES.LOCKED_OUT,
  [PACE_SETTER_SITUATION_CODES.L402]: PACE_SETTER_SITUATION_TYPES.OUT_OF_FUEL,
  [PACE_SETTER_SITUATION_CODES.L403]: PACE_SETTER_SITUATION_TYPES.OUT_OF_FUEL,
  [PACE_SETTER_SITUATION_CODES.T483]: PACE_SETTER_SITUATION_TYPES.OUT_OF_FUEL,
  [PACE_SETTER_SITUATION_CODES.T480]: PACE_SETTER_SITUATION_TYPES.NEED_A_TOW,
  [PACE_SETTER_SITUATION_CODES.T180]: PACE_SETTER_SITUATION_TYPES.FLAT_TIRE,
  [PACE_SETTER_SITUATION_CODES.L101]: PACE_SETTER_SITUATION_TYPES.FLAT_TIRE,
  [PACE_SETTER_SITUATION_CODES.T2]: PACE_SETTER_SITUATION_TYPES.FLAT_TIRE,
  [PACE_SETTER_SITUATION_CODES.T1]: PACE_SETTER_SITUATION_TYPES.FLAT_TIRE,
  [PACE_SETTER_SITUATION_CODES.T3]: PACE_SETTER_SITUATION_TYPES.BATTERY_ISSUE,
  [PACE_SETTER_SITUATION_CODES.T4]: PACE_SETTER_SITUATION_TYPES.CAR_WONT_START,
  [PACE_SETTER_SITUATION_CODES.T5]: PACE_SETTER_SITUATION_TYPES.OUT_OF_FUEL,
  [PACE_SETTER_SITUATION_CODES.T6]: PACE_SETTER_SITUATION_TYPES.NEED_A_TOW,
  [PACE_SETTER_SITUATION_CODES.T7A]: PACE_SETTER_SITUATION_TYPES.LOCKED_OUT,
  [PACE_SETTER_SITUATION_CODES.T7B]: PACE_SETTER_SITUATION_TYPES.LOCKED_OUT,
  [PACE_SETTER_SITUATION_CODES.T7]: PACE_SETTER_SITUATION_TYPES.LOCKED_OUT,
  [PACE_SETTER_SITUATION_CODES.T8]: PACE_SETTER_SITUATION_TYPES.STUCK,
}

export function getPaceSetterNameByCode(code: PACE_SETTER_SITUATION_CODES) {
  return paceSetterMap[code] || ''
}

export function getPacesetterCodeByValue(value: string) {
  const index = Object.values(PACE_SETTER_SITUATION_CODES).indexOf(value as PACE_SETTER_SITUATION_CODES);
  return PACE_SETTER_SITUATION_CODES[Object.keys(PACE_SETTER_SITUATION_CODES)[index]]
}

export const PACE_SETTER_OPTION_TEMPLATES = (): IndexedCollection<PaceSetterOptions> => ({
  [PACE_SETTER_SITUATION_TYPES.ACCIDENT]: {
    name: 'Accident',
    label: LABEL_ACCIDENT_OPT(),
    defaultCode: {
      name: 'Accident',
      paceSetterCode: PACE_SETTER_SITUATION_CODES.T906,
      requiresTowing: true,
    },
    detailGroups: [],
  },
  [PACE_SETTER_SITUATION_TYPES.STUCK]: {
    name: 'Vehicle Stuck',
    label: LABEL_STUCK_OPT(),
    pageLoadTagging: {
      pageType: issue.PAGE_TYPE,
      pageName: issue.PAGE_NAME_SORRY,
      issueType: 'Stuck',
    },
    defaultCode: {
      name: 'Vehicle Stuck',
      paceSetterCode: PACE_SETTER_SITUATION_CODES.T931,
      requiresTowing: true,
    },
    detailGroups: [
      {
        heading: HEADER_STUCK(),
        subHeading: SUB_HEADER_STUCK(),
        subHeadingClass: 'small-title',
        lists: [
          {
            name: 'Vehicle Stuck',
            mandatory: true,
            multiSelect: false,
            values: [
              {
                name: 'Yes, my vehicle will need to be towed to another location.',
                label: LIST_VALUES_YES_STUCK(),
                paceSetterCode: PACE_SETTER_SITUATION_CODES.T932,
                paceSetterOverrideKey: PACE_SETTER_OVERRIDE_TYPES.STUCK,
                requiresTowing: true,
                comment:
                    'Yes, my vehicle will need to be towed to another location.',
              },
              {
                name: `No, I don't need a tow`,
                label: LIST_VALUES_NO_STUCK(),
                paceSetterCode: PACE_SETTER_SITUATION_CODES.T931,
                paceSetterOverrideKey: PACE_SETTER_OVERRIDE_TYPES.STUCK_NO_TOW,
                requiresTowing: false,
                comment: `No, I don't need a tow`,
              },
            ],
          },
        ],
      },
    ],
  },
  [PACE_SETTER_SITUATION_TYPES.BATTERY_ISSUE]: {
    name: 'Battery Test or Replace',
    label: LABEL_BATTERY_ISSUE_OPT(),
    defaultCode: {
      name: 'Battery Test or Replace',
      paceSetterCode: PACE_SETTER_SITUATION_CODES.L302,
      requiresTowing: false,
    },
    detailGroups: [],
  },
  [PACE_SETTER_SITUATION_TYPES.FLAT_TIRE]: {
    name: 'Flat Tire',
    label: LABEL_FLAT_TIRE_OPT(),
    pageLoadTagging: {
      pageType: issue.PAGE_TYPE,
      pageName: issue.PAGE_NAME_SPARE_TIRES,
      issueType: 'Flat Tire',
    },
    detailGroups: [
      {
        heading: HEADER_TIRE(),
        allowNotes: false,
        noteInstructions: NOTES_TIRE(),
        lists: [
          {
            name: 'spares',
            mandatory: true,
            values: [
              {
                name: 'Yes',
                label: LIST_VALUES_YES_TIRE(),
                tagLabel: 'Spare Tires?',
                alternateLabel: FLAT_TIRE_HAVE_SPARE(),
                paceSetterCode: PACE_SETTER_SITUATION_CODES.L101,
                paceSetterOverrideKey: PACE_SETTER_OVERRIDE_TYPES.TIRE_SPARE,
                requiresTowing: false,
                comment: 'Have spare for each flat tire',
              },
              {
                name: 'No',
                label: LIST_VALUES_NO_TIRE(),
                tagLabel: 'Spare Tires?',
                alternateLabel: FLAT_TIRE_DONT_HAVE_SPARE(),
                paceSetterCode: PACE_SETTER_SITUATION_CODES.T180,
                paceSetterOverrideKey: PACE_SETTER_OVERRIDE_TYPES.TIRE_NO_SPARE,
                requiresTowing: true,
                comment: 'Don\'t have spare for each flat tire',
              },
            ],
          },
        ],
      },
    ],
  },
  [PACE_SETTER_SITUATION_TYPES.LOCKED_OUT]: {
    name: 'Locked Out',
    label: LABEL_LOCKED_OUT_OPT(),
    pageLoadTagging: {
      pageType: issue.PAGE_TYPE,
      pageName: issue.PAGE_NAME_KEYS,
      issueType: 'Locked Out',
    },
    defaultCode: {
      name: 'Locked Out',
      paceSetterCode: PACE_SETTER_SITUATION_CODES.L701,
    },
    detailGroups: [
      {
        heading: HEADER_LOCKED(),
        isOptional: false,
        lists: [
          {
            name: 'key location',
            mandatory: true,
            multiSelect: false,
            values: [
              {
                name: 'Locked in the vehicle',
                label: LIST_VALUES_IN_VEHICLE_LOCKED(),
                paceSetterCode: PACE_SETTER_SITUATION_CODES.L701,
                paceSetterOverrideKey: PACE_SETTER_OVERRIDE_TYPES.LOCK_KEY_INSIDE,
                requiresTowing: false,
                comment: 'Locked in the vehicle',
                needAdditionalRequirement: true,
              },
              {
                name: 'My key is broken or lost',
                label: LIST_VALUES_BROKEN_LOST_LOCKED(),
                paceSetterCode: PACE_SETTER_SITUATION_CODES.L890,
                paceSetterOverrideKey: PACE_SETTER_OVERRIDE_TYPES.LOCK_KEY_LOST,
                requiresTowing: false,
                comment: 'My key is broken or lost',
                needAdditionalRequirement: true,
              },
              {
                name: `My key won't turn in the ignition`,
                label: LIST_VALUES_WONT_TURN_LOCKED(),
                paceSetterCode: PACE_SETTER_SITUATION_CODES.L801,
                paceSetterOverrideKey: PACE_SETTER_OVERRIDE_TYPES.LOCK_KEY_NO_TURN,
                requiresTowing: false,
                comment: `My key won't turn in the ignition`,
              },
              {
                name: `My key won't unlock the door`,
                label: LIST_VALUES_WONT_UNLOCK_LOCKED(),
                paceSetterCode: PACE_SETTER_SITUATION_CODES.L890,
                paceSetterOverrideKey: PACE_SETTER_OVERRIDE_TYPES.LOCK_KEY_NO_WORK,
                requiresTowing: false,
                comment: `My key won't unlock the door`,
                needAdditionalRequirement: true,
              },
              {
                name: `I don't know`,
                label: LIST_VALUES_IDK_LOCKED(),
                paceSetterCode: PACE_SETTER_SITUATION_CODES.L701,
                paceSetterOverrideKey: PACE_SETTER_OVERRIDE_TYPES.LOCK_UNSURE,
                requiresTowing: false,
              },
            ],
          },
        ],
        additionalRequirements: {
          name: 'priority situation',
          heading: HEADER_ADD_REQUIREMENTS_LOCKED(),
          mandatory: false,
          multiSelect: false,
          values: [
            {
              name: PACE_SETTER_SITUATION_ADDITIONAL_REQUIREMENTS_TYPES.LOVED_ONE_LOCKED_IN_CAR,
              label: LIST_VALUES_LOVED_ONE_LOCKED(),
              requiresTowing: false,
              comment: 'A loved one is locked in the car',
              paceSetterCode: PACE_SETTER_SITUATION_CODES.L701,
            },
            {
              name: PACE_SETTER_SITUATION_ADDITIONAL_REQUIREMENTS_TYPES.MEDICATION_LOCKED_IN_CAR,
              label: LIST_VALUES_MEDICATION_LOCKED(),
              requiresTowing: false,
              comment: 'My medication is locked in the car',
              paceSetterCode: PACE_SETTER_SITUATION_CODES.L701,
            },
          ],
        },
      },
    ],
  },
  [PACE_SETTER_SITUATION_TYPES.OUT_OF_FUEL]: {
    name: 'Fuel/EV Charge',
    label: LABEL_OUT_OF_FUEL_OPT(),
    pageLoadTagging: {
      pageType: issue.PAGE_TYPE,
      pageName: issue.PAGE_NAME_FUEL_TYPE,
      issueType: 'Fuel/EV Charge',
    },
    detailGroups: [
      {
        heading: HEADER_FUEL(),
        subHeading: SUB_HEADER_FUEL(),
        lists: [
          {
            name: 'fuelType',
            mandatory: true,
            values: [
              {
                name: FUEL_TYPES.GASOLINE,
                label: LIST_VALUES_GAS_FUEL(),
                paceSetterCode: PACE_SETTER_SITUATION_CODES.L402,
                paceSetterOverrideKey: PACE_SETTER_OVERRIDE_TYPES.FUEL_GAS,
                requiresTowing: false,
                comment: 'Gas Vehicle',
              },
              {
                name: FUEL_TYPES.DIESEL,
                label: LIST_VALUES_DIESEL_FUEL(),
                paceSetterCode: PACE_SETTER_SITUATION_CODES.L403,
                paceSetterOverrideKey: PACE_SETTER_OVERRIDE_TYPES.FUEL_DIESEL,
                requiresTowing: false,
                comment: 'Diesel Vehicle',
              },
              {
                name: FUEL_TYPES.ELECTRIC,
                label: LIST_VALUES_ELECTRIC_FUEL(),
                paceSetterCode: PACE_SETTER_SITUATION_CODES.T483,
                paceSetterOverrideKey: PACE_SETTER_OVERRIDE_TYPES.FUEL_ELECTRIC,
                requiresTowing: true,
                comment: 'Electric Vehicle',
              },
              {
                name: FUEL_TYPES.HYDROGEN_ALT,
                label: LIST_VALUES_HYDRO_FUEL(),
                paceSetterOverrideKey: PACE_SETTER_OVERRIDE_TYPES.FUEL_HYDROGEN_ALT,
                comment: 'Hydrogen/Alternate',
              },
            ],
          },
        ],
      },
    ],
  },
  [PACE_SETTER_SITUATION_TYPES.NEED_A_TOW]: {
    name: 'Need a tow',
    label: LABEL_NEED_TOW_OPT(),
    defaultCode: {
      name: 'Need a tow',
      paceSetterCode: PACE_SETTER_SITUATION_CODES.T480,
      requiresTowing: true,
    },
    detailGroups: [],
  },
  [PACE_SETTER_SITUATION_TYPES.CAR_WONT_START]: {
    name: `My Car Won't Start`,
    label: LABEL_CAR_WONT_START_OPT(),
    pageLoadTagging: {
      pageType: issue.PAGE_TYPE,
      pageName: issue.PAGE_NAME_WHATS_HAPPENING,
      issueType: 'Car Won\'t Start',
    },
    detailGroups: [
      {
        heading: HEADER_WONT_START(),
        allowNotes: false,
        noteInstructions: NOTES_WONT_START(),
        lists: [
          {
            name: `Car Won't Start`,
            mandatory: true,
            multiSelect: false,
            values: [
              {
                name: 'Vehicle died while driving',
                label: LIST_VALUES_DIED_WONT_START(),
                paceSetterCode: PACE_SETTER_SITUATION_CODES.T680,
                paceSetterOverrideKey: PACE_SETTER_OVERRIDE_TYPES.WONT_START_DIED,
                comment: 'Vehicle died while driving',
                requiresTowing: true,
              },
              {
                name: 'I turn the key and nothing happens',
                label: LIST_VALUES_NOTHING_WONT_START(),
                paceSetterCode: PACE_SETTER_SITUATION_CODES.L302,
                paceSetterOverrideKey: PACE_SETTER_OVERRIDE_TYPES.WONT_START,
                comment: 'I turn the key and nothing happens',
              },
              {
                name: 'I turn the key and hear a clicking sound',
                label: LIST_VALUES_CLICKING_WONT_START(),
                paceSetterCode: PACE_SETTER_SITUATION_CODES.L302,
                paceSetterOverrideKey: PACE_SETTER_OVERRIDE_TYPES.WONT_START_CLICK,
                comment: 'I turn the key and hear a clicking sound',
              },
              {
                name: 'I turn the key and my car cranks, but does not start',
                label: LIST_VALUES_CRANKS_WONT_START(),
                paceSetterCode: PACE_SETTER_SITUATION_CODES.T480,
                paceSetterOverrideKey: PACE_SETTER_OVERRIDE_TYPES.WONT_START_CRANK,
                comment: 'I turn the key and my car cranks, but does not start',
                requiresTowing: true,
              },
              {
                name: 'Battery issue',
                label: LIST_VALUES_BATTERY_WONT_START(),
                paceSetterCode: PACE_SETTER_SITUATION_CODES.L301,
                paceSetterOverrideKey: PACE_SETTER_OVERRIDE_TYPES.WONT_START_BATT,
                comment: 'Battery issue',
              },
            ],
          },
        ],
      },
    ],
  },
})

export const defaultOptions = {
  name: 'Service Request',
  label: LABEL_SERVICE_REQUEST_OPT(),
  detailGroups: [] as any,
  defaultCode: undefined,
}

export function setElectricOnlyFuelType(template: PaceSetterOptions) {
  template.detailGroups = []
  template.defaultCode = {
    name: FUEL_TYPES.ELECTRIC,
    paceSetterCode: PACE_SETTER_SITUATION_CODES.T483,
    paceSetterOverrideKey: PACE_SETTER_OVERRIDE_TYPES.FUEL_ELECTRIC,
    requiresTowing: true,
  }
  return template
}

export function filterPaceSetterSituation(situation: PaceSetterSituation) {
  const template = PACE_SETTER_OPTION_TEMPLATES()[situation.name.toUpperCase()] || defaultOptions
  const filterElectricIssue = !situation.allowChangeVehicle &&
    situation.name.toUpperCase() === PACE_SETTER_SITUATION_TYPES.OUT_OF_FUEL &&
    situation.vehicleFuelType?.toUpperCase() === FUEL_TYPE_ELECTRIC
  return filterElectricIssue ? setElectricOnlyFuelType(template) : template
}

